/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from "react";

interface Props {
	title: string;
	isActive: boolean;
	className?: string;
	onClick?: () => void;
}

const RadioButton: FC<Props> = ({ isActive, title, onClick, className }) => {
	return (
		<div
			onClick={onClick ? onClick : () => {}}
			className="flex gap-[10px] items-center cursor-pointer"
		>
			<div className="w-4 h-4 rounded-full border border-grey200 flex items-center justify-center">
				{isActive && <span className="w-[10px] h-[10px] bg-blue rounded-full" />}
			</div>

			<p className={`text-grey400 text-xs font-medium leading-3 ${className}`}>{title}</p>
		</div>
	);
};

export default RadioButton;
