import React, { FC } from "react";
import { DarkChevron } from "../../../../../../../assets/icons";
import { Link } from "react-router-dom";

interface Props {
	url?: string;
	icon: string;
	title: string;
	className?: string;
	textStyles?: string;
	iconStyles?: string;
	onclick?: () => void;
}

const SidebarItem: FC<Props> = ({
	icon,
	title,
	url,
	onclick,
	className,
	iconStyles,
	textStyles,
}) => {
	return (
		<>
			{url ? (
				<Link to={url || ""} className={`flex justify-between items-center ${className}`}>
					<div className="flex items-center gap-[15px]">
						<div
							className={`w-[30px] h-[30px] flex items-center justify-center bg-grey100 rounded-full ${iconStyles}`}
						>
							<img src={icon} alt="icon" className="w-4 h-4" />
						</div>
						<p className={`text-sm font-medium leading-[14px] ${textStyles}`}>
							{title}
						</p>
					</div>

					<img src={DarkChevron} alt="chevron" />
				</Link>
			) : (
				<div
					onClick={onclick}
					className={`flex justify-between items-center cursor-pointer ${className}`}
				>
					<div className="flex items-center gap-[15px]">
						<div
							className={`w-[30px] h-[30px] flex items-center justify-center bg-grey100 rounded-full ${iconStyles}`}
						>
							<img src={icon} alt="icon" className="w-4 h-4" />
						</div>
						<p className={`text-sm font-medium leading-[14px] ${textStyles}`}>
							{title}
						</p>
					</div>

					<img src={DarkChevron} alt="chevron" />
				</div>
			)}
		</>
	);
};

export default SidebarItem;
