import React, { FC } from "react";
import { EmptyIcon } from "../../assets/icons";

interface Props {
	text: string;
	className?: string;
}

export const EmptySection: FC<Props> = ({ text, className }) => {
	return (
		<div className={`flex flex-col gap-[25px] items-center justify-center ${className}`}>
			<img src={EmptyIcon} alt="empty-icon" />
			<p className="text-center text-xs font-normal">{text}</p>
		</div>
	);
};
