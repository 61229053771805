import { createSlice } from "@reduxjs/toolkit";
import { _CREATE_SETTLEMENT, _GET_BANK, _MARK_SAVINGS } from "./dashboardAction";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
	error: null,
	loading: false,
	banks: [],
};

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		clearDashboardState: () => {
			return {
				...initialState,
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(_CREATE_SETTLEMENT.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_CREATE_SETTLEMENT.pending, state => {
			state.loading = true;
		});
		builder.addCase(_CREATE_SETTLEMENT.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_GET_BANK.fulfilled, (state, { payload }) => {
			state.banks = payload;
		});

		builder.addCase(_MARK_SAVINGS.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_MARK_SAVINGS.pending, state => {
			state.loading = true;
		});
		builder.addCase(_MARK_SAVINGS.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});
	},
});

// eslint-disable-next-line no-empty-pattern
export const { clearDashboardState } = dashboardSlice.actions;
export default dashboardSlice.reducer;
