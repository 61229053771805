import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authSlice from "./auth/authSlice";
import e_cardSlice from "./e-card/e_cardSlice";
import customerSlice from "./customer/customerSlice";
import dashboardSlice from "./dashboard/dashboardSlice";

// export const store = configureStore({
//   reducer: {
//     auth: authSlice,
//     customer: customerSlice,
//     e_card: e_cardSlice,
//     dashboard: dashboardSlice,
//   },
// });

const reducers = combineReducers({
	auth: authSlice,
	customer: customerSlice,
	e_card: e_cardSlice,
	dashboard: dashboardSlice,
});

const persistConfig = {
	key: "root",
	storage,
	version: 1,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
