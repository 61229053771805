import React, { useEffect } from "react";
import Layout from "../../components/layout/layout";
import { account_type } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _GET_CONTRIBUTORS } from "../../redux/e-card/e_cardAction";
import CustomModal from "../../components/custom-modal/custom-modal";
import { SettlementForm, TopNavigation } from "../../components";

const Settlement = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	useEffect(() => {
		dispatch(_GET_CONTRIBUTORS());
	}, []);

	return (
		<>
			{isMerchant ? (
				<Layout>
					<TopNavigation rightTitle="Settlement" />
					<SettlementForm />
				</Layout>
			) : (
				<CustomModal>
					<TopNavigation rightTitle="WITHDRAW" />
					<SettlementForm />
				</CustomModal>
			)}
		</>
	);
};

export default Settlement;
