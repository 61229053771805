import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from ".";

export const NoContributor = () => {
	const navigate = useNavigate();
	return (
		<div className="pt-10 pb-5 max-w-[337px] mx-auto flex flex-col items-center justify-center w-full">
			<h4 className="text-lg font-semibold">No contributor found</h4>
			<p className="pt-2.5 text-center text-grey400 text-xs font-normal leading-[20px] !max-w-[310px]"></p>
			<Button
				isValid={true}
				type={"button"}
				title={"Create New E-Card"}
				onClick={() => navigate("/create-e-card/contributor-information")}
				className="mt-10 !min-w-fit"
			/>
		</div>
	);
};
