/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	BusinessDetails,
	ECard,
	HelpFaq,
	Profile,
	ReferEarn,
	SecurityIcon,
	TransactionIcon,
} from "../assets/icons";
import type { IGroupedTransactions, ISidebarItem, ITransactions } from "../types";

export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const Months = [
	{ name: "January", identifier: 1 },
	{ name: "February", identifier: 2 },
	{ name: "March", identifier: 3 },
	{ name: "April", identifier: 4 },
	{ name: "May", identifier: 5 },
	{ name: "June", identifier: 6 },
	{ name: "July", identifier: 7 },
	{ name: "August", identifier: 8 },
	{ name: "September", identifier: 9 },
	{ name: "October", identifier: 10 },
	{ name: "November", identifier: 11 },
	{ name: "December", identifier: 12 },
];

const date = new Date();

const dayOfWeek = days[date.getDay()];
const month = months[date.getMonth()];
const dayOfMonth = date.getDate();
const year = date.getFullYear();

const paddedDayOfMonth = dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth;

export const thisDay = `${dayOfWeek} ${month} ${paddedDayOfMonth}, ${year}`;

// export const groupTransactionsByDate = (

//   transactionData: ITransactions[]
// ): IGroupedTransactions[] => {
//   if (transactionData !== undefined) {
//     const groupedNotifications: Record<string, any> = transactionData?.reduce(
//       (array: Record<string, any>, transaction: any) => {
//         const date = transaction?.created_date?.trim();
//         if (array[getDateString(date)]) {
//           array[getDateString(date)].push(transaction);
//         } else {
//           array[getDateString(date)] = [transaction];
//         }
//         return array;
//       },
//       {}
//     );

//     const restructuredData = Object?.entries(groupedNotifications)?.map(
//       ([date, transactions]) => ({
//         date,
//         transactions,
//       })
//     );
//     return restructuredData;
//   }
//   return [];
// };

export const groupTransactionsByDate = (
	transactionData: ITransactions[],
): IGroupedTransactions[] => {
	if (transactionData !== undefined) {
		const groupedNotifications: Record<string, any[]> = transactionData.reduce(
			(array: Record<string, any[]>, transaction: any) => {
				const date = transaction?.created_date?.trim();
				const dateString = getDateString(date);
				if (array[dateString]) {
					array[dateString].push(transaction);
				} else {
					array[dateString] = [transaction];
				}
				return array;
			},
			{},
		);

		const restructuredData = Object.entries(groupedNotifications).map(
			([date, transactions]) => ({
				date,
				transactions: transactions.sort(
					(a, b) =>
						new Date(b.created_date).getTime() - new Date(a.created_date).getTime(),
				),
			}),
		);

		// Sort the restructured data by date in ascending order
		restructuredData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

		return restructuredData;
	}
	return [];
};

export const Gender = [
	{ identifier: "Male", name: "Male" },
	{ identifier: "Female", name: "Female" },
];

// export const Contributors = [
//   { identifier: 'Daniel', name: 'Daniel Anu', avatar: Avatar },
//   { identifier: 'Anu', name: 'Anu Daniel', avatar: Avatar },
// ];

export const VerificationType = [
	{ identifier: "NIC", name: "National Identity Card" },
	{ identifier: "DL", name: "Driver’s License" },
	{ identifier: "IP", name: "International Passport" },
	{ identifier: "PVC", name: "Permanent Voter's Card." },
];

export const ProductType = [
	{ identifier: "fixed", name: "Fixed Savings" },
	{
		identifier: "flexible",
		name: "Flexible Savings",
	},
];

export const Frequency = [
	{ identifier: "daily", name: "Daily" },
	{ identifier: "weekly", name: "Weekly" },
	{ identifier: "bi-weekly", name: "Bi-Weekly" },
	{ identifier: "monthly", name: "Monthly" },
];

export const Duration = [
	{ identifier: "90", name: "3 months" },
	{ identifier: "180", name: "6 months" },
	{ identifier: "270", name: "9 months" },
	{ identifier: "365", name: "12 months" },
];

export const ModeOfSettlement = [
	{ identifier: "Cash", name: "Cash" },
	{ identifier: "Bank transfer", name: "Bank transfer" },
];

const MerchantSidebarItems: ISidebarItem[] = [
	{ title: "E-Cards", icon: ECard, url: "/e-card/logs" },
	{ title: "Transactions", icon: TransactionIcon, url: "/transactions" },
	{
		title: "Business Details",
		icon: BusinessDetails,
		url: "/profile",
	},
	{ title: "Security", icon: SecurityIcon, url: "/account-settings" },
	{ title: "Refer & Earn", icon: ReferEarn },
	{ title: "Help & FAQs", icon: HelpFaq },
];

const IndividualSidebarItems: ISidebarItem[] = [
	{
		title: "Summary",
		icon: Profile,
		url: ["/dashboard", "/transactions", "/transaction-details"],
	},
	{ title: "eCards", icon: ECard, url: ["/e-card/logs", "/edit-e-card"] },
	{ title: "Vaults", icon: TransactionIcon, url: ["/vaults"] },
	// { title: "Profile", icon: BusinessDetails, url: ["/profile", "/add-account"] },
	{ title: "Refer & Earn", icon: ReferEarn },
];

export const SidebarArray = (accountType: string) => {
	if (accountType === "merchant") {
		return MerchantSidebarItems;
	} else {
		return IndividualSidebarItems;
	}
};

export const Banks = [
	{ name: "Access bank", bankCode: "0976", identifier: "access" },
	{ name: "Kuda bank", bankCode: "0976", identifier: "kuda" },
	{ name: "GT bank", bankCode: "0976", identifier: "gt" },
	{ name: "Ecobank", bankCode: "0976", identifier: "ecobank" },
	{ name: "Polaris bank", bankCode: "0976", identifier: "polaris" },
];

export const account_type = (accountType: string) => {
	const isMerchant = true;
	if (accountType?.toLocaleLowerCase() === "merchant") {
		return isMerchant;
	} else {
		return !isMerchant;
	}
};

export function truncate(text: string): string {
	const atIndex = text?.indexOf("@");
	const domain = text?.slice(atIndex);
	const firstTexts = text?.slice(0, 4);

	return firstTexts + "****" + domain;
}

export const getCountdownTime = (duration: number) => {
	let seconds = duration;

	const countdownInterval = setInterval(() => {
		seconds--;
		if (seconds <= 0) {
			clearInterval(countdownInterval);
		}
	}, 1000);

	return () => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return { minutes, remainingSeconds };
	};
};

export const getDateString = (date: any) => {
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const d = new Date(date);
	const day = String(d.getDate()).padStart(2, "0");
	const month = months[d.getMonth()];
	const year = d.getFullYear();

	return `${month} ${day}, ${year}`;
};

export function getTimeString(isoString: Date) {
	const date = new Date(isoString);

	let hours: any = date.getUTCHours();
	const minutes = date.getUTCMinutes().toString().padStart(2, "0");

	const ampm = hours >= 12 ? "PM" : "AM";

	hours = hours % 12;
	hours = hours ? hours : 12;
	hours = hours.toString().padStart(2, "0");

	return `${hours}:${minutes} ${ampm}`;
}

export const daysToMonths = (days: number) => {
	if (isNaN(days) || days < 0) {
		throw new Error("Invalid number of days. It should be a non-negative integer.");
	}

	const months = days / 30;

	return months === 1 ? "1 month" : `${months} months`;
};

export const formatAmount = (value: number | string) => ((value as number) * 1).toLocaleString();

export const generateHexColorForText = (email: string): string => {
	const hash = email?.split("").reduce((acc, char) => {
		return char.charCodeAt(0) + ((acc << 5) - acc);
	}, 0);

	const color =
		`#${((hash >> 2) & 0xff).toString(16).padStart(2, "0")}` +
		`${((hash >> 10) & 0xff).toString(16).padStart(2, "0")}` +
		`${((hash >> 6) & 0xff).toString(16).padStart(2, "0")}`;

	return color.length === 7 ? color : color.padEnd(7, "0");
};

export const getRandomColor = () => {
	const letters = "0123456789ABCDEF";
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
};

export const getAbbr = (user: string | null): string => {
	let abbr;

	if (user) {
		const words = user?.split(" ");

		if (words?.length >= 2) {
			const firstLetterFirstWord = words[0].charAt(0);
			const firstLetterSecondWord = words[1].charAt(0);
			abbr = `${firstLetterFirstWord}${firstLetterSecondWord}`;
			return abbr;
		} else {
			const firstLetterFirstWord = words[0].charAt(0);
			const firstLetterSecondWord = words[0].charAt(1);
			abbr = `${firstLetterFirstWord}${firstLetterSecondWord}`;
			return abbr;
		}
	}
	return "--";
};

export function generateColorFromName(fullName: string) {
	let hash = 0;

	for (let i = 0; i < fullName.length; i++) {
		hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		color += ("00" + value.toString(16)).slice(-2);
	}

	return color;
}
