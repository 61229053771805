import React, { FC } from "react";
import Logo from "../logo/logo";
import PageTitle from "../page-title/page-title";
import { TopNavigation } from "../shared";

interface Props {
	title: string;
	subText: string;
	hasBackButton?: boolean;
	hasStep?: boolean;
	currentStep?: number;
	totalStep?: number;
	hasNoLogo?: boolean;
}

const OnboardTitle: FC<Props> = ({
	title,
	subText,
	hasStep,
	hasNoLogo,
	totalStep,
	currentStep,
	hasBackButton,
}) => {
	return (
		<div className={`flex flex-col gap-10 ${hasBackButton && "gap-[30px]"}`}>
			{!hasNoLogo && <Logo />}
			{hasBackButton && <TopNavigation />}
			<PageTitle
				title={title}
				subText={subText}
				hasStep={hasStep}
				totalStep={totalStep}
				currentStep={currentStep}
			/>
		</div>
	);
};

export default OnboardTitle;
