/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { account_type } from "../../utils";
import { MerchantEcardDetails } from "../../components/merchant";
import { IndividualEcardDetails } from "../../components/individual/individual-ecard-details";

const MerchantECardDetails = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	return <>{isMerchant ? <MerchantEcardDetails /> : <IndividualEcardDetails />}</>;
};

export default MerchantECardDetails;
