import { createSlice } from "@reduxjs/toolkit";
import {
	_FORGOT_PASSWORD,
	_OTP_VERIFICATION,
	_RESET_PASSWORD,
	_RESEND_OTP,
	_CREATE_PIN,
	_CREATE_CUSTOMER,
	_PROFILE,
	_GET_TRANSACTIONS,
	_COMPLETE_MERCHANT_PROFILE,
	_CHANGE_PASSWORD,
	_RESET_PIN,
	// _INDIVIDUAL_ACCOUNT,
} from "./customerActions";
import { ICustomerState } from "../../types";

const initialState: ICustomerState = {
	loading: false,
	error: null,
	createCustomer: {},
	forgotPassword: {},
	otpVerification: {},
	createPassword: {},
	profile: {},
	transactions: [],
};

const customerSlice = createSlice({
	name: "customer",
	initialState: initialState,
	reducers: {
		clearCustomerState: () => {
			return {
				...initialState,
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(_CREATE_CUSTOMER.fulfilled, (state, { payload }) => {
			state.createCustomer = payload;
			state.loading = false;
		});
		builder.addCase(_CREATE_CUSTOMER.pending, state => {
			state.loading = true;
		});
		builder.addCase(_CREATE_CUSTOMER.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_FORGOT_PASSWORD.fulfilled, (state, { payload }) => {
			state.forgotPassword = payload;
			state.loading = false;
		});
		builder.addCase(_FORGOT_PASSWORD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_FORGOT_PASSWORD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_RESEND_OTP.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_RESEND_OTP.pending, state => {
			state.loading = true;
		});
		builder.addCase(_RESEND_OTP.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_OTP_VERIFICATION.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_OTP_VERIFICATION.pending, state => {
			state.loading = true;
		});
		builder.addCase(_OTP_VERIFICATION.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_RESET_PASSWORD.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_RESET_PASSWORD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_RESET_PASSWORD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_CHANGE_PASSWORD.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_CHANGE_PASSWORD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_CHANGE_PASSWORD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder
			.addCase(_CREATE_PIN.fulfilled, (state, { payload }) => {
				state.createPassword = payload;
				state.loading = false;
			})
			.addCase(_CREATE_PIN.pending, state => {
				state.loading = true;
			})
			.addCase(_CREATE_PIN.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
			});

		builder.addCase(_RESET_PIN.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_RESET_PIN.pending, state => {
			state.loading = true;
		});
		builder.addCase(_RESET_PIN.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_PROFILE.fulfilled, (state, { payload }) => {
			state.profile = payload;
			// eslint-disable-next-line no-empty-function
		});
		builder.addCase(_PROFILE.pending, state => {
			state.loading = true;
		});
		builder.addCase(_PROFILE.rejected, (state, { payload }) => {
			state.error = payload;
		});

		// builder.addCase(_INDIVIDUAL_ACCOUNT.fulfilled, (state, { payload }) => {
		//   state.profile = payload;
		//   // eslint-disable-next-line no-empty-function
		// });
		// builder.addCase(_INDIVIDUAL_ACCOUNT.pending, (state) => {
		//   state.loading = true;
		// });
		// builder.addCase(_INDIVIDUAL_ACCOUNT.rejected, (state, { payload }) => {
		//   state.error = payload;
		// });

		builder.addCase(_GET_TRANSACTIONS.fulfilled, (state, { payload }) => {
			state.transactions = payload;
			state.loading = false;
		});
		builder.addCase(_GET_TRANSACTIONS.pending, state => {
			state.loading = true;
		});
		builder.addCase(_GET_TRANSACTIONS.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});
		builder.addCase(_COMPLETE_MERCHANT_PROFILE.fulfilled, state => {
			state.loading = false;
		});
		builder.addCase(_COMPLETE_MERCHANT_PROFILE.pending, state => {
			state.loading = true;
		});
		builder.addCase(_COMPLETE_MERCHANT_PROFILE.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});
	},
});

// eslint-disable-next-line no-empty-pattern
export const { clearCustomerState } = customerSlice.actions;
export default customerSlice.reducer;
