import React, { FC } from "react";
import BackButton from "../back-button/back-button";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { account_type } from "../../utils";
import { RootState } from "../../redux/store";

interface Props {
	onClick?: () => void;
	middleTitle?: string;
	rightTitle?: string;
}

export const TopNavigation: FC<Props> = ({ onClick, middleTitle, rightTitle }) => {
	const { pathname } = useLocation();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	return (
		<div
			className={`md:relative w-full flex items-center justify-between top-0
			md:pb-[30px] border-b border-grey100 bg-white z-[99] left-0 right-0 md:pt-0 md:px-0
			${pathname === "/dashboard" && "!absolute !px-0 !pt-0"} ${isMerchant ? "fixed p-5" : "pb-5 px-0"}
      `}
		>
			<BackButton onClick={onClick} />
			{middleTitle && <h2 className="text-sm font-semibold leading-[18px]">{middleTitle}</h2>}
			{rightTitle ? (
				<div className="flex items-center gap-1.5">
					<div className="w-1 h-1 bg-violet-400 rounded-full" />{" "}
					<h2 className="text-sm font-semibold leading-none">{rightTitle}</h2>
				</div>
			) : (
				<div className="w-20" />
			)}
		</div>
	);
};
