import React from "react";
import Layout from "../layout/layout";
import { TopNavigation } from "../shared";
import MerchantECardInput from "../../pages/e-card-details/component/Merchant-E-card-details-input";

export function MerchantEcardDetails() {
	return (
		<Layout>
			<TopNavigation middleTitle="e-Card Details" />
			{/* <MerchantECardProperties /> */}
			<MerchantECardInput />
		</Layout>
	);
}
