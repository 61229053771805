import React from "react";
import { TitleIcon } from "../../assets/icons";

interface IndividualSectionTitleProps {
	title: string;
	icon?: string;
	onClick?: () => void;
	isActive?: boolean;
}

const IndividualSectionTitle = ({
	icon,
	title,
	onClick,
	isActive = true,
}: IndividualSectionTitleProps) => {
	return (
		<div
			onClick={onClick}
			className={`bg-grey100 py-1.5 px-2.5 rounded flex items-center gap-2 min-w-fit max-w-fit h-[32px] cursor-pointer ${!isActive && "opacity-30"}`}
		>
			<img src={icon ? icon : TitleIcon} alt="icon" />
			<span className="text-[10px] font-semibold text-black">{title}</span>
		</div>
	);
};

export { IndividualSectionTitle };
