/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountType, IResetPassword, ISignUp } from "../../types";
import customerService from "./customerService";
import { _CURRENT_USER } from "../auth/authActions";
import axios from "axios";

export const _CREATE_CUSTOMER = createAsyncThunk(
	"CREATE_CUSTOMER",
	async (
		{
			firstname,
			lastname,
			email_address,
			password,
			confirm_password,
			customer_type,
			phone_number,
		}: ISignUp,
		{ rejectWithValue },
	) => {
		try {
			const res = await customerService.CREATE_CUSTOMER(
				firstname,
				lastname,
				email_address,
				password,
				confirm_password,
				customer_type,
				phone_number,
			);
			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _FORGOT_PASSWORD = createAsyncThunk(
	"FORGOT_PASSWORD",
	async ({ email }: { email: string; navigate: any }, { rejectWithValue }) => {
		try {
			const res = await customerService.FORGOT_PASSWORD(email);
			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _CREATE_PIN = createAsyncThunk(
	"CREATE_PASSCODE",
	async ({ pin }: { pin: string }, { rejectWithValue }) => {
		try {
			const res = await customerService.CREATE_PIN(pin);
			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _RESET_PIN = createAsyncThunk(
	"RESET_PIN",
	async (
		{
			navigate,
			current_pin,
			new_pin,
			confirm_new_pin,
		}: {
			current_pin: string;
			new_pin: string;
			confirm_new_pin: string;
			navigate: any;
		},
		{ rejectWithValue },
	) => {
		try {
			const res: any = await customerService.RESET_PIN({
				current_pin,
				new_pin,
				confirm_new_pin,
			});

			navigate("/success", {
				state: {
					title: "Transaction pin reset successfully.",
					redirectTo: "/dashboard",
					btnTitle: "Dashboard",
					message: "",
				},
			});
			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _RESEND_OTP = createAsyncThunk(
	"RESEND_OTP",
	async ({ email }: { email: string }, { rejectWithValue }) => {
		try {
			const res = await customerService.RESEND_OTP(email);
			return res.data;
		} catch (err: any) {
			const error = err.response.data.message;
			return rejectWithValue(error);
		}
	},
);

export const _OTP_VERIFICATION = createAsyncThunk(
	"OTP_VERIFICATION",
	async (
		{ otp, email_address }: { otp: string; email_address: string | null },
		{ rejectWithValue, dispatch },
	) => {
		try {
			const res = await customerService.OTP_VERIFICATION(otp, email_address);
			const token: string = res?.data?.data?.token;
			if (token) {
				localStorage.setItem("token", token);
			}
			dispatch(_CURRENT_USER({ token }));
			return res.data;
		} catch (err: any) {
			const error = err.response.data || err.code;
			return rejectWithValue(error);
		}
	},
);

export const _RESET_PASSWORD = createAsyncThunk(
	"RESET_PASSWORD",
	async (
		{ email, new_password, confirm_new_password, token, navigate }: IResetPassword,
		{ rejectWithValue },
	) => {
		try {
			const res = await customerService.RESET_PASSWORD(
				email,
				token,
				new_password,
				confirm_new_password,
			);

			navigate("/sign-in");
			return res.data;
		} catch (err: any) {
			const error = err.response.data.message;
			return rejectWithValue(error);
		}
	},
);

export const _CHANGE_PASSWORD = createAsyncThunk(
	"CHANGE_PASSWORD",
	async (
		{
			current_password,
			new_password,
			confirm_new_password,
			navigate,
		}: {
			current_password: string;
			new_password: string;
			confirm_new_password: string;
			navigate: any;
		},
		{ rejectWithValue },
	) => {
		try {
			const res = await customerService.CHANGE_PASSWORD(
				current_password,
				new_password,
				confirm_new_password,
			);

			navigate("/success", {
				state: {
					title: "Password changed successfully",
					redirectTo: "/dashboard",
					btnTitle: "Continue",
					message:
						"Your Merchant Account has been created Successfully. You can now accept and manage payment, while you earn passively on Dashi.",
				},
			});
			return res.data;
		} catch (err: any) {
			const error = err.response.data.message;
			return rejectWithValue(error);
		}
	},
);

export const _UPLOAD_FILE = createAsyncThunk(
	"UPLOAD_FILE",
	async (image: File, { rejectWithValue }) => {
		const headers = {
			"Content-Type": "multipart/form-data",
		};

		const formData = new FormData();
		formData.append("file", image);
		formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "");
		formData.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || "");
		formData.append("folder", "IdentityDocs");

		try {
			const res = await axios.post(
				`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
				formData,
				{ headers },
			);

			return res.data.secure_url;
		} catch (err: any) {
			const error: string = err.response.data.message;
			rejectWithValue(error);
		}
	},
);

export const _PROFILE = createAsyncThunk(
	"DASHBOARD",
	async (accountType: AccountType, { rejectWithValue }) => {
		try {
			const res = await customerService.PROFILE(accountType);
			return res.data.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _GET_TRANSACTIONS = createAsyncThunk(
	"GET_TRANSACTIONS",
	async (_: void, { rejectWithValue }) => {
		try {
			const res = await customerService.GET_TRANSACTIONS();
			return res.data.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _COMPLETE_MERCHANT_PROFILE = createAsyncThunk(
	"COMPLETE_MERCHANT_PROFILE",
	async (
		{
			navigate,
			business_name,
			business_address,
			CAC_number,
			means_of_identification,
			means_of_identification_upload_url,
			next_of_kin_name,
			next_of_kin_address,
			next_of_kin_relationship,
			next_of_kin_phone_number,
			next_of_kin_email_address,
		}: {
			navigate: any;
			business_name: string;
			business_address: string;
			CAC_number?: string;
			means_of_identification: string;
			means_of_identification_upload_url: File;
			next_of_kin_name: string;
			next_of_kin_address: string;
			next_of_kin_relationship: string;
			next_of_kin_phone_number: string;
			next_of_kin_email_address: string;
		},
		{ rejectWithValue, dispatch },
	) => {
		const idImage: any = await dispatch(
			_UPLOAD_FILE(means_of_identification_upload_url),
		).unwrap();

		try {
			const res = await customerService.COMPLETE_MERCHANT_PROFILE({
				business_name,
				business_address,
				CAC_number,
				means_of_identification,
				means_of_identification_upload_url: idImage,
				next_of_kin_name,
				next_of_kin_address,
				next_of_kin_relationship,
				next_of_kin_phone_number,
				next_of_kin_email_address,
			});

			navigate("/success", {
				state: {
					title: "You’re all set!",
					redirectTo: "/dashboard",
					btnTitle: "Continue to Dashboard",
					message:
						"Your Merchant Account has been created Successfully. You can now accept and manage payment, while you earn passively on Dashi.",
				},
			});
			return res.data.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);
