import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { IndividualSectionTitle } from ".";

interface ListCardWrapperProps {
	children: ReactNode;
	titleArray?: { title: string; icon?: string }[]; // Array of tab titles
	actionComponent?: ReactNode;
	activeTabIndex?: number;
	setActiveTabIndex?: Dispatch<SetStateAction<number>>;
}

export function ListCardWrapper({
	children,
	titleArray,
	actionComponent,
	setActiveTabIndex,
	activeTabIndex = 0,
}: ListCardWrapperProps) {
	return (
		<div className="bg-white rounded-xl p-5 mt-7 md:mt-10 overflow-hidden">
			{titleArray && (
				<div className="flex items-center justify-between pb-5 border-b">
					{titleArray && (
						<div className="flex gap-3 overflow-x-auto no-scrollbar">
							{titleArray?.map((item, index) => (
								<IndividualSectionTitle
									key={index}
									title={item.title}
									icon={item.icon}
									onClick={() => setActiveTabIndex?.(index)}
									isActive={activeTabIndex === index}
								/>
							))}
						</div>
					)}
					{actionComponent}
				</div>
			)}

			{children}
		</div>
	);
}
