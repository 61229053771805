/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { _ADD_ACCOUNT } from "../../redux/dashboard/dashboardAction";
import { toastify } from "../toastify/toastify";
import { SelectField } from "./select-field";
import { Banks } from "../../utils";
import { InputField } from "./input-field";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Button } from "./button";

export const AddAccountForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [accountName] = useState<string | null>(null);
	const { profile } = useSelector((state: RootState) => state.customer);

	return (
		<Formik
			validateOnMount
			initialValues={{
				bankName: "",
				accountNumber: "",
				bvn: profile.bvn || "",
			}}
			validationSchema={Yup.object({
				bvn: Yup.string().required("This is a required field"),
				bankName: Yup.string().required("This is a required field"),
				accountNumber: Yup.string()
					.min(10, "Invalid account number")
					.max(10, "Invalid account number")
					.required("This is a required field"),
			})}
			onSubmit={async (values, { setErrors }) => {
				const { bvn, bankName, accountNumber } = values;
				const errors = {};
				if (Object.entries(errors).length === 0 && errors.constructor === Object) {
					try {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const res: any = await dispatch(
							_ADD_ACCOUNT({
								bvn,
								navigate,
								bankName,
								accountNumber,
								customer_id: profile?.customer_id,
							}),
						).unwrap();
					} catch (error: any) {
						return toastify("error", error.message);
					}

					navigate("/success", {
						state: {
							title: "Bank added successful",
							redirectTo: "/profile",
							message:
								"At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.",
						},
					});
				} else {
					setErrors(errors);
				}
			}}
		>
			{({ isValid, handleSubmit, setFieldValue }) => (
				<Form onSubmit={handleSubmit} className="flex flex-col gap-10">
					<SelectField
						data={Banks}
						label={"Select Bank*"}
						placeholder={"Select bank"}
						setFieldValue={(value: string) => {
							setFieldValue("bankCode", value);
							setFieldValue("bankName", value);
						}}
					/>
					<InputField
						type="number"
						name="accountNumber"
						isAccountNumber={true}
						label="Account Number*"
						placeholder="12345678"
						accountName={accountName}
					/>

					{!profile.bvn && (
						<InputField
							type="number"
							name="bvn"
							label="Bank Verification Number*"
							placeholder="12345678"
						/>
					)}

					<Button isValid={isValid} type={"submit"} title={"Add Bank"} />
				</Form>
			)}
		</Formik>
	);
};
