/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISettlement } from "../../types";
import api from "../../utils/api";

class DashboardService {
	CREATE_SETTLEMENT({
		settlement_amount,
		transaction_pin,
		mode_of_settlement,
		contributor_name,
		ecard_id,
		accountType,
	}: ISettlement) {
		const url =
			accountType?.toLocaleLowerCase() === "merchant"
				? `/settlement/create/${ecard_id}`
				: `/individual/settlement/create/${ecard_id}`;
		return api.post(url, {
			settlement_amount: String(settlement_amount),
			transaction_pin,
			mode_of_settlement,
			contributor_name,
		});
	}

	MARK_SAVINGS({
		selected_dates,
		transaction_pin,
		contributor_name,
		contributor_id,
		ecard_id,
		accountType,
		proof_of_payment,
	}: any) {
		const url =
			accountType?.toLocaleLowerCase() === "merchant"
				? `/saving/mark/${ecard_id}/${contributor_id}`
				: `/individual/saving/mark-saving/${ecard_id}`;
		const body =
			accountType?.toLocaleLowerCase() === "merchant"
				? {
						selected_dates,
						transaction_pin,
						contributor_name,
					}
				: {
						selected_dates,
						transaction_pin,
						proof_of_payment,
					};

		return api.post(url, body);
	}

	ADD_BANK({ bvn, bankName, accountNumber, merchant_id }: any) {
		return api.post(`/bank/add-account/${merchant_id}`, {
			bvn,
			bankName,
			accountNumber,
		});
	}

	GET_BANK() {
		return api.get("/bank/get-bank");
	}
}

export default new DashboardService();
