import React, { useState } from "react";
import { TopNavigation } from "../shared";
import EcardInput from "../../pages/e-card/component/EcardInput";
import EcardLogs from "../../pages/e-card/component/EcardLogs";

export function MerchantEcardPage() {
	const [query, setQuery] = useState<string>("");
	return (
		<>
			<TopNavigation middleTitle="e-Cards" />
			<div className="pt-10 md:pt-0">
				<EcardInput setQuery={setQuery} />
				<EcardLogs query={query} />
			</div>
		</>
	);
}
