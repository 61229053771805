/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthService from "./authServices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISignIn } from "../../types";
import { _RESEND_OTP } from "../customer/customerActions";
import { jwtDecode } from "jwt-decode"; // If using jwt-decode
import { clearAuthState } from "./authSlice";
import { clearCustomerState } from "../customer/customerSlice";
import { clearDashboardState } from "../dashboard/dashboardSlice";
import { clearECardState } from "../e-card/e_cardSlice";

export const _SIGN_IN = createAsyncThunk(
	"SIGN_IN",
	async ({ password, email_address, navigate }: ISignIn, { rejectWithValue, dispatch }) => {
		try {
			localStorage.setItem("email", email_address);
			const res = await AuthService.SIGN_IN(password, email_address);
			const token: string = res?.data?.data?.token;
			if (token) {
				localStorage.setItem("token", token);
			}
			dispatch(_CURRENT_USER({ token }));
			const decoded = jwtDecode(token);
			return { ...res?.data, decoded };
		} catch (err: any) {
			const error = err?.response?.data;

			if (error?.isCustomerVerified === 234) {
				dispatch(_RESEND_OTP({ email: email_address }));
				setTimeout(() => {
					navigate("/email-confirmation", { state: { type: "verification" } });
				}, 1000);
			}
			return rejectWithValue(
				error === "Rejected" ? "Please try again with a stable internet connection" : error,
			);
		}
	},
);

export const _SIGN_OUT = createAsyncThunk(
	"SIGN_OUT",
	async (_: void, { rejectWithValue, dispatch }) => {
		try {
			localStorage.clear();
			dispatch(_CURRENT_USER({}));
			dispatch(clearAuthState());
			dispatch(clearCustomerState());
			dispatch(clearDashboardState());
			dispatch(clearECardState());
		} catch (err: any) {
			const error = err?.response?.data?.message;
			localStorage.clear();
			dispatch(_CURRENT_USER({}));
			dispatch(clearAuthState());
			dispatch(clearCustomerState());
			dispatch(clearDashboardState());
			dispatch(clearECardState());
			return rejectWithValue(error);
		}
	},
);

export const _CURRENT_USER = createAsyncThunk(
	"CURRENT_USER",
	async ({ token }: { token?: string | undefined | null | object }) => {
		return token;
	},
);

export const _DECODED_USER = createAsyncThunk("DECODED_USER", async (data: any) => {
	return data;
});
