/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Layout from "../../components/layout/layout";
import { account_type } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ListCardWrapper, TopNavigation } from "../../components";
import { AddAccountForm } from "../../components/shared/add-account-form";

const AddAccount = () => {
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	return (
		<Layout>
			{isMerchant && <TopNavigation middleTitle="ADD BANK" />}

			{isMerchant ? (
				<div className="pt-[60px] md:pt-10 flex flex-col gap-10 ">
					<AddAccountForm />
				</div>
			) : (
				<ListCardWrapper>
					<AddAccountForm />
				</ListCardWrapper>
			)}
		</Layout>
	);
};

export default AddAccount;
