import React, { FC } from "react";
import { LogoIcon } from "../../assets/icons";
import { Link } from "react-router-dom";

interface Props {
	isSmall?: boolean;
}

const Logo: FC<Props> = ({ isSmall }) => {
	return (
		<Link
			to="/dashboard"
			className={`rounded-[10px] flex items-center justify-center bg-lightBlue ${
				isSmall ? "w-10 h-10" : "w-[60px] h-[60px]"
			}`}
		>
			<img src={LogoIcon} alt="logo" className={`${isSmall && "h-[26px] w-[28px]"}`} />
		</Link>
	);
};

export default Logo;
