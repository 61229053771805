import React, { FC, ReactNode } from "react";

interface Props {
	children: ReactNode;
}

const SettingsContainer: FC<Props> = ({ children }) => {
	return (
		<div className="border border-[rgba(239, 242, 247, 1)] rounded-[24px] p-5 flex flex-col gap-[24px]">
			{children}
		</div>
	);
};

export default SettingsContainer;
