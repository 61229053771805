/* eslint-disable react/react-in-jsx-scope */
import { Form, Formik } from "formik";
import SectionTitle from "../../create-ecard/section-title/section-title";
import MerchantInputField from "../../e-card-details/component/Merchant-E-card-input-field";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import FileUpload from "../../create-ecard/contributors-information/file-upload/file-upload";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
	Duration,
	Frequency,
	Gender,
	ProductType,
	account_type,
	daysToMonths,
} from "../../../utils";
import { Button, SelectField } from "../../../components";

const EditMerchantInput = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const data = state?.data;
	const { loading } = useSelector((state: RootState) => state.e_card);
	const { user } = useSelector((state: RootState) => state.auth);
	const { profile } = useSelector((state: RootState) => state.customer);
	const isMerchant = account_type(user?.userType);
	const isEcard = data?.product && true;

	return (
		<>
			<Formik
				validateOnMount
				enableReinitialize
				initialValues={{
					profile_pic: data?.profile_pic || "",
					phone_number: isMerchant
						? data?.phone_number
							? data?.phone_number.length === 10
								? `+234${data?.phone_number}`
								: data?.phone_number
							: ""
						: profile?.phone_number,
					gender: data?.gender ? (data?.gender === 1 ? "male" : "female") : "",
					bvn: data?.bvn || "",
					nin: data?.bvn || "",
					product_type: data?.product?.product_type || "",
					minimum_amount: data?.product?.minimum_amount || "",
					minimum_frequency: data?.product?.minimum_frequency || "",
					minimum_duration: data?.product?.minimum_duration || "",
					residential_address: data?.residential_address || "",
					nok_first_name: data?.nok_first_name || "",
					nok_phone_number: data?.nok_phone_number || "",
					nok_email_address: data?.nok_email_address || "",
				}}
				validationSchema={Yup.object({})}
				onSubmit={async (values, { setErrors }) => {
					const {
						profile_pic,
						phone_number,
						gender,
						bvn,
						nin,
						product_type,
						minimum_amount,
						minimum_frequency,
						minimum_duration,
						residential_address,
						nok_first_name,
						nok_phone_number,
						nok_email_address,
					} = values;
					const errors = {};
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						navigate("/confirm-transaction", {
							state: {
								type: "update-e-card",
								data: {
									ecard_id: data?.ecard_id,
									contributor_id: data.contributor_id,
									profile_pic,
									phone_number,
									gender,
									bvn,
									nin,
									product_type,
									minimum_amount,
									minimum_frequency,
									minimum_duration,
									residential_address,
									nok_first_name,
									nok_phone_number,
									nok_email_address,
								},
							},
						});
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ isValid, values, setFieldValue, handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						<div className="flex items-center justify-center">
							<FileUpload
								type="avatar"
								data={data}
								name={"profile_pic"}
								value={data?.profile_pic}
								setFieldValue={value => setFieldValue("profile_pic", value)}
							/>
						</div>

						<div>
							<SectionTitle
								title={"Personal Information"}
								className={
									"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
								}
							/>

							<div className="py-[1rem] gap-5 flex justify-between ">
								<div className="!w-[50%]">
									<MerchantInputField
										label="Phone Number"
										name="phone_number"
										placeholder=""
										type="phone"
										classname="!bg-[#d4d4d4]"
										disabled
									/>
								</div>

								<SelectField
									noChevron
									data={Gender}
									label={"Gender*"}
									value={values.gender}
									placeholder={""}
									setFieldValue={(value: string) =>
										setFieldValue("gender", value)
									}
									className="w-[50%] !bg-grey100 !h-[48px] px-4 py-1 rounded-[.5rem] "
									labelClassName="absolute text-grey400 !text-[10px] font-normal"
									inputClassName="px-0 min-w-full py-0 mt-3 font-semibold !text-sm !text-black"
								/>
							</div>

							<MerchantInputField
								label="Residential Address"
								name="residential_address"
								placeholder=""
								type="text"
								classname=""
							/>
						</div>

						<div className="py-[1rem] gap-5 flex justify-between ">
							<div className="!w-[50%]">
								<MerchantInputField
									label="BVN"
									name="bvn"
									placeholder=""
									type="text"
								/>
							</div>

							<div className="!w-[50%]">
								<MerchantInputField label="NIN" name="nin" placeholder="" />
							</div>
						</div>

						{isEcard && (
							<div>
								<SectionTitle
									title={"eCard Details"}
									className={
										"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
									}
								/>
								<div className="py-[1rem] flex gap-5 justify-between ">
									<SelectField
										noChevron
										data={ProductType}
										type="product_type"
										label="Product type"
										value={values.product_type}
										placeholder={values.product_type}
										setFieldValue={(value: string) =>
											setFieldValue("product_type", value)
										}
										className="w-[50%] bg-grey100 !h-[48px] px-4 py-1 rounded-[.5rem] "
										labelClassName="absolute text-grey400 !text-[10px] font-normal"
										inputClassName="px-0 min-w-full py-0 mt-3 font-semibold !text-sm !text-black"
									/>

									<div className="!w-[50%]">
										<MerchantInputField
											label="Amount"
											name="minimum_amount"
											placeholder=""
											classname=""
										/>
									</div>
								</div>

								<div className="w-full flex flex-row gap-5">
									<SelectField
										noChevron
										data={Frequency}
										label={"Frequency*"}
										placeholder={""}
										value={data?.product?.minimum_frequency}
										setFieldValue={(value: string) =>
											setFieldValue("frequency", value)
										}
										className={`bg-grey100 !h-[48px] px-4 py-1 rounded-[.5rem] ${
											values.product_type.toLocaleLowerCase() === "fixed" &&
											"w-[50%]"
										} ${
											values.product_type.toLocaleLowerCase() ===
												"flexible" && "!min-w-full"
										}`}
										labelClassName="absolute text-grey400 !text-[10px] font-normal"
										inputClassName="px-0 min-w-full py-0 mt-3 font-semibold !text-sm !text-black"
									/>
									{values.product_type.toLocaleLowerCase() === "fixed" && (
										<SelectField
											noChevron
											data={Duration}
											type="minimum_duration"
											label="Duration"
											placeholder={""}
											value={daysToMonths(values.minimum_duration)}
											setFieldValue={(value: string) =>
												setFieldValue("minimum_duration", value)
											}
											className="w-[50%] bg-grey100 !h-[48px] px-4 py-1 rounded-[.5rem] "
											labelClassName="absolute text-grey400 !text-[10px] font-normal"
											inputClassName="px-0 min-w-full py-0 mt-3 font-semibold !text-sm !text-black"
										/>
									)}
								</div>
							</div>
						)}

						{
							<div>
								<SectionTitle
									title={"Next of Kin"}
									className={
										"text-grey400 text-xs border-b border-grey100 h-[30px] mt-10"
									}
								/>
								<div className="py-[1rem] flex gap-5 justify-between ">
									<div className="!w-[50%]">
										<MerchantInputField
											label="First name"
											name="nok_first_name"
											placeholder=""
											type="text"
											classname=""
										/>
									</div>

									<div className="!w-[50%]">
										<MerchantInputField
											label="Phone Number"
											name="nok_phone_number"
											placeholder=""
											type="phone"
											classname=""
										/>
									</div>
								</div>
								<MerchantInputField
									label="Email Address"
									name="nok_email_address"
									placeholder=""
									type="email"
									classname=""
								/>
							</div>
						}
						<div className="flex gap-4 justify-end mt-[2.5rem]">
							<Button
								isValid={true}
								type={"button"}
								title={"Cancel"}
								onClick={() => navigate(-1)}
								className="!px-5 !w-fit md:px-10 bg-lightRed !text-red"
							/>

							<Button
								isValid={isValid}
								type={"submit"}
								title={"Save Changes"}
								isLoading={loading}
								className={"bg-blue text-white text-[12px] !w-[176px]"}
							/>
							{/* <MerchantButton
                type={'button'}
                title={'Save Changes'}
                className={'bg-blue text-white text-[12px] !w-[176px]'}
              /> */}
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default EditMerchantInput;
