import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../redux/store";

const PrivateRoutes = () => {
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);
	return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRoutes;
