/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Layout from "../../components/layout/layout";
import OnboardTitle from "../../components/onboard-title/onboard-title";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _RESET_PASSWORD } from "../../redux/customer/customerActions";
import { toastify } from "../../components/toastify/toastify";
import { Button, InputField } from "../../components";

const ResetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const email = queryParams.get("email_address") || "";
	const token = queryParams.get("token") || "";

	const { loading } = useSelector((state: RootState) => state.customer);

	return (
		<Layout>
			<OnboardTitle
				title={"Create password"}
				subText={"Enter your email address to reset password"}
			/>

			<Formik
				validateOnMount
				initialValues={{
					new_password: "",
					confirm_new_password: "",
				}}
				validationSchema={Yup.object({
					new_password: Yup.string()
						.matches(
							/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;':"-]).{8,}$/,
							"Must At Least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
						)
						.required("This is required field"),
					confirm_new_password: Yup.string()
						.required("This is a required field")
						.test("passwords-match", "Passwords must match", function (value) {
							return this.parent.new_password === value;
						}),
				})}
				onSubmit={async (values, { setErrors }) => {
					const { new_password, confirm_new_password } = values;
					const errors = {};
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						try {
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							const res = await dispatch(
								_RESET_PASSWORD({
									email,
									navigate,
									new_password,
									confirm_new_password,
									token,
								}),
							).unwrap();
							return toastify("success", "Password Changed Successfully");
						} catch (error: any) {
							return toastify("error", error);
						}
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ isValid, handleSubmit }) => (
					<Form onSubmit={handleSubmit} className="mt-10 flex flex-col gap-[30px]">
						<InputField
							type="password"
							name="new_password"
							label="New Password*"
							placeholder="Password (8+ characters)"
						/>

						<InputField
							type="password"
							name="confirm_new_password"
							label="Confirm New Password*"
							placeholder="Password (8+ characters)"
						/>

						<Button
							type={"submit"}
							isValid={isValid}
							isLoading={loading}
							title={"Change Password"}
						/>
					</Form>
				)}
			</Formik>
		</Layout>
	);
};

export default ResetPassword;
