/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { BlackStar, BlueStar } from "../../../../assets/icons";

const Level = ({ item, isActive }: { item: any; isActive?: boolean }) => {
	return (
		<div
			className={`flex w-full bg-grey100 border border-grey300 rounded-[10px] h-[97px] items-center px-5 ${isActive ? "border-blue" : "border-grey300 "}`}
		>
			<div className="flex items-start gap-3">
				<img src={isActive ? BlueStar : BlackStar} alt="" className="w-6 h-6" />

				<div>
					<h4 className="text-base font-semibold text-black">{item.title}</h4>
					<p className="text-xs max-w-[209px] text-grey400 leading-[18px]">
						Maximum balance : ₦{item.max}
					</p>
					<p className="text-xs max-w-[209px] text-grey400 leading-[18px]">{item.text}</p>
				</div>
			</div>
		</div>
	);
};

export default Level;
