import React, { FC } from "react";

interface Props {
	title: string;
	className?: string;
}

const SectionTitle: FC<Props> = ({ title, className }) => {
	return <h4 className={`text-sm font-semibold leading-none ${className}`}>{title}</h4>;
};

export default SectionTitle;
