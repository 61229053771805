/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useRef, useState } from "react";
import useEscapeKey from "../../hooks/use-escape-key/use-escape-key";
import useOutsideClick from "../../hooks/use-outside-click/use-outside-click";
import { Chevron, SearchIcon } from "../../assets/icons";
import { AnimatePresence, motion } from "framer-motion";
import { NoContributor } from "./no-contributor";
import { useLocation } from "react-router-dom";
import { Initials } from "./initials";

interface SelectProps {
	data: any;
	type?: string;
	label?: string;
	value?: string;
	noChevron?: boolean;
	placeholder: string;
	className?: string;
	modalClassName?: string;
	labelClassName?: string;
	inputClassName?: string;
	isContributor?: boolean;
	setValue?: (value: any) => void;
	setFieldValue: (value: string) => void;
}

export const SelectField: FC<SelectProps> = ({
	data,
	type,
	label,
	value,
	noChevron,
	setValue,
	className,
	placeholder,
	modalClassName,
	isContributor,
	setFieldValue,
	inputClassName,
	labelClassName,
}) => {
	const ref = useRef(null);
	const { pathname } = useLocation();
	const [query, setQuery] = useState<string>("");
	const [modal, setModal] = useState<boolean>(false);
	const [selectedItem, setSelected] = useState<any>(value ? value : null);

	const toggleModal = () => {
		setModal(!modal);
	};

	const handleClose = () => {
		setModal(false);
	};

	useEscapeKey(handleClose);
	useOutsideClick(handleClose, ref);

	return (
		<div className={`relative cursor-pointer flex flex-col gap-[10px] ${className}`} ref={ref}>
			{label && (
				<label
					htmlFor=""
					className={`text-xs font-medium leading-[14px] ${labelClassName}`}
				>
					{label}
				</label>
			)}

			<div
				className={`h-[46px] p-[15px] bg-gray-50 rounded-[32px]
        text-xs font-normal leading-[14px] text-grey400 flex items-center justify-between ${
			modal && !noChevron && "border !border-green bg-lightGreen"
		} ${inputClassName} ${selectedItem === null ? "!text-grey300" : "!text-black"}`}
				onClick={toggleModal}
			>
				<p className={` ${selectedItem === null ? "" : "capitalize"}`}>
					{selectedItem !== null ? (
						isContributor ? (
							<span className="font-semibold">
								{selectedItem?.first_name} {selectedItem?.last_name} -{" "}
								<span className="font-normal uppercase">
									CN:
									{selectedItem.contributor_identifier}
								</span>
							</span>
						) : selectedItem.name ? (
							selectedItem.name
						) : selectedItem.ecard_name ? (
							selectedItem.ecard_name
						) : (
							selectedItem
						)
					) : (
						placeholder
					)}
				</p>
				{!noChevron && (
					<span className={`duration-300 ease-in-out ${modal ? "rotate-180" : ""}`}>
						<img src={Chevron} alt="Chevron" />
					</span>
				)}
			</div>

			<AnimatePresence>
				{modal && (
					<motion.div
						initial={{
							height: 0,
						}}
						animate={{
							height: "auto",
							maxHeight: 284,
							transition: {
								height: {
									duration: 0.4,
								},
							},
						}}
						exit={{
							height: 0,
							transition: {
								height: {
									duration: 0.4,
								},
							},
						}}
						className={`select absolute top-[70px] left-0 right-0 z-50 no-scrollbar 
						${pathname.includes("contributor-information") || pathname.includes("mark-savings") ? "!max-h-[203px]" : "!max-h-[284px]"}
						bg-white rounded-lg px-4 flex flex-col select overflow-scroll ${noChevron && "!top-[50px]"} ${modalClassName}`}
					>
						{isContributor && data.length > 1 && (
							<div className="relative w-full">
								<input
									type="text"
									onChange={e => setQuery(e.currentTarget.value)}
									placeholder="Search for contributor"
									className="w-full pt-[21px] pb-2.5 border-b outline-none 
                  					pl-[26px] text-neutral-400 text-xs font-normal font-['Montserrat'] leading-[14px]"
								/>
								<img src={SearchIcon} alt="" className="absolute top-[21px]" />
							</div>
						)}
						{data?.filter(
							(item: any) =>
								query.trim() === "" ||
								item?.first_name?.toLowerCase().includes(query.toLowerCase()) ||
								item?.last_name?.toLowerCase().includes(query.toLowerCase()),
						).length === 0 && isContributor ? (
							<NoContributor />
						) : (
							data
								.filter(
									(item: any) =>
										query.trim() === "" ||
										item?.first_name
											?.toLowerCase()
											.includes(query.toLowerCase()) ||
										item?.last_name
											?.toLowerCase()
											.includes(query.toLowerCase()),
								)
								.map(
									(
										// item: { identifier: string; avatar: string; name: string },
										item: any,
										index: number,
									) => (
										<div
											key={index}
											onClick={() => {
												setSelected(item);
												handleClose();
												setValue?.(item);
												setQuery("");
												setFieldValue(
													type === "product_type"
														? item?.identifier
														: isContributor
															? `${item?.first_name} ${item?.last_name}`
															: item?.identifier,
												);
											}}
											className={`text-slate-900 text-xs font-normal 
                      leading-[14px] py-4 flex items-center gap-2.5 
                      ${data.length - 1 !== index && " border-b border-grey100-400"}`}
										>
											{isContributor && (
												<Initials
													size="m"
													fullName={`${item?.first_name} ${item?.last_name}`}
												/>
											)}

											{item.name ||
												item.ecard_name ||
												`${item?.first_name} ${item?.last_name}`}
										</div>
									),
								)
						)}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
};
