/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _CHANGE_PASSWORD } from "../../redux/customer/customerActions";
import { toastify } from "../../components/toastify/toastify";
import { Button, InputField } from "../../components";

const ChangePassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { loading } = useSelector((state: RootState) => state.customer);

	return (
		<CustomModal>
			<div>
				{" "}
				<Formik
					enableReinitialize
					validateOnMount
					key={"code"}
					initialValues={{
						current_password: "",
						new_password: "",
						confirm_new_password: "",
					}}
					validationSchema={Yup.object({
						current_password: Yup.string().required("This is a required field"),
						new_password: Yup.string()
							.matches(
								/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;':"-]).{8,}$/,
								"Must At Least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
							)
							.required("This is a required field"),
						confirm_new_password: Yup.string()
							.required("This is a required field")
							.test("passwords-match", "Passwords must match", function (value) {
								return this.parent.new_password === value;
							}),
					})}
					onSubmit={async (values, { setErrors }) => {
						const errors = {};
						if (Object.entries(errors).length === 0 && errors.constructor === Object) {
							try {
								await dispatch(_CHANGE_PASSWORD({ ...values, navigate })).unwrap();
							} catch (error: any) {
								toastify("error", error);
							}
						} else {
							setErrors(errors);
						}
					}}
				>
					{({ handleSubmit, isValid }) => (
						<Form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
							<div className="verify-email-content">
								<h6 className="text-black text-base md:text-[20px] md:leading-[24px] font-semibold">
									{"Change password"}
								</h6>
								<p className="mt-[4px] md:mt-2.5 text-grey400 text-xs md:text-sm font-normal md:leading-[16px]">
									{"Enter your email address to reset password"}
								</p>
							</div>

							<div className="w-full flex flex-col gap-[30px]">
								<InputField
									type={"password"}
									label={"Current Password*"}
									name={"current_password"}
									placeholder={"Password (8+ characters)"}
								/>

								<InputField
									type={"password"}
									label={"New Password*"}
									name={"new_password"}
									placeholder={"Password (8+ characters)"}
								/>

								<InputField
									type={"password"}
									label={"Confirm New Password*"}
									name={"confirm_new_password"}
									placeholder={"Password (8+ characters)"}
								/>

								<div className="flex gap-4 self-end">
									<Button
										isValid={true}
										type={"button"}
										title={"Cancel"}
										onClick={() => navigate(-1)}
										className="!w-[89px] !bg-[#F6F4FF] !text-blue"
									/>
									<Button
										type={"submit"}
										isValid={isValid}
										isLoading={loading}
										title={"Change Password"}
										className="!w-[170px] md:!w-fit"
									/>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</CustomModal>
	);
};

export default ChangePassword;
