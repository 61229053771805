import React from "react";
import Layout from "../../components/layout/layout";
import OnboardTitle from "../../components/onboard-title/onboard-title";
import RadioButton from "../business-details/components/radio-button/radio-button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { account_type } from "../../utils";
import WindowResizeHook from "../../hooks/window-resize-hook/window-rezise-hook";
import { Button } from "../../components";

const MerchantVerification = () => (
	<>
		<RadioButton title={"Business Verification"} isActive={false} className="!text-black" />
		<RadioButton title={"Next of Kin"} isActive={false} className="!text-black" />
		<RadioButton title={"Identity Verification"} isActive={false} className="!text-black" />
	</>
);

const IndividualVerification = () => (
	<>
		<RadioButton title={"Address Verification"} isActive={false} className="!text-black" />
		<RadioButton title={"Add BVN to your account"} isActive={false} className="!text-black" />
		<RadioButton title={"Identity Verification"} isActive={false} className="!text-black" />
	</>
);

const BusinessVerification = () => {
	const navigate = useNavigate();
	const [windowWidth] = WindowResizeHook();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user.userType);

	return (
		<Layout>
			<div className="mt-[60px] md:mt-0" />
			<OnboardTitle
				hasNoLogo
				hasBackButton
				title={"You’re almost there"}
				subText={
					"In order to complete your profile, please provide us with the required information."
				}
			/>

			<div className="flex flex-col gap-10 mt-10">
				{isMerchant ? <MerchantVerification /> : <IndividualVerification />}

				<div
					className={
						windowWidth > 640
							? "mt-[200px]"
							: "absolute bottom-10 md:bottom-20 left-5 right-5"
					}
				>
					<Button
						isValid={true}
						type={"button"}
						title={"Continue"}
						onClick={() => navigate("/business-details")}
					/>
				</div>
			</div>
		</Layout>
	);
};

export default BusinessVerification;
