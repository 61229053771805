/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Layout from "../../components/layout/layout";
import SectionTitle from "../create-ecard/section-title/section-title";
import Level from "./components/level/level";
import { TopNavigation } from "../../components";

const AccountLimits = () => {
	const tiers = [
		{
			title: "Tier 1 - Beginner",
			max: "300,000",
			text: "Upgrade to be able to send money",
		},
		{
			title: "Tier 1 - Beginner",
			max: "300,000",
			text: "Upgrade to be able to send money",
		},
		{
			title: "Tier 3 - Beginner",
			max: "300,000",
			text: "Upgrade to be able to send money",
		},
	];

	return (
		<Layout>
			<TopNavigation middleTitle={"Account Limits"} />

			<div className="mt-[30px] flex flex-col gap-5 pt-10 md:pt-0">
				<SectionTitle title={"Current Level"} className="text-black font-medium text-xs" />

				<Level item={tiers[0]} isActive />
			</div>

			<div className="mt-10 flex flex-col gap-5">
				<SectionTitle
					title={"Upgrade your account"}
					className="text-black font-medium text-xs"
				/>

				{tiers.map((item: any, index: number) => (
					<Level key={index} item={item} />
				))}
			</div>
		</Layout>
	);
};

export default AccountLimits;
