import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../redux/store";
import { account_type, getDateString } from "../../utils";
import { _PROFILE } from "../../redux/customer/customerActions";
import { _GET_BANK } from "../../redux/dashboard/dashboardAction";
import { Button, TopNavigation } from "../shared";
import { EditIcon, UserAvatar } from "../../assets/icons";
import ProfileItem from "../../pages/business-profile/components/profile-item/profile-item";
import Layout from "../layout/layout";

export function MerchantProfile() {
	const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const { profile } = useSelector((state: RootState) => state.customer);
	const isMerchant = account_type(user?.userType);

	useEffect(() => {
		dispatch(_PROFILE(user?.userType));
		dispatch(_GET_BANK());
	}, [user]);

	return (
		<Layout>
			<TopNavigation middleTitle={isMerchant ? "Business Profile" : "Personal Details"} />
			<>
				<div className="mt-10 w-full flex flex-col pt-10 md:pt-0">
					<div className="!w-[80px] h-[80px] rounded-full self-center">
						<img src={UserAvatar} className="self-center w-20 h-20" alt="avatar" />
					</div>
					<div className="flex flex-col items-center justify-center gap-[6px] mt-5">
						<h5 className="text-base font-semibold flex items-center gap-1">
							{profile?.business_name || "--"}{" "}
							{/* <img src={GreenStar} alt="start" /> */}
						</h5>
						<p className="text-sm text-grey400 text-medium">
							({profile?.customer_identifier || "--"})
						</p>
					</div>
				</div>

				<div className="mt-10 flex flex-col gap-10">
					<ProfileItem title={"ACCOUNT MANAGER"} value={profile?.customer_name || "--"} />

					<ProfileItem
						title={"CONTACT PHONE NUMBER"}
						value={profile?.phone_number || "--"}
					/>

					<ProfileItem
						title={"CONTACT EMAIL ADDRESS"}
						value={profile?.email_address || "--"}
					/>

					<ProfileItem
						title={"OFFICE LOCATION"}
						value={profile?.business_address || "--"}
					/>

					<ProfileItem title={"NEXT OF KIN"} value={profile?.next_of_kin_name || "--"} />

					{/* <ProfileItem
          title={'BANK INFORMATION'}
          value={
            <span
              onClick={() => navigate('/add-account')}
              className="flex items-center gap-2 font-medium text-blue cursor-pointer !w-fit"
            >
              <img src={Pen} alt="" /> Setup Bank Details
            </span>
          }
        /> */}

					{/* <ProfileItem
          title={'BANK INFORMATION'}
          value={
            <div className="flex items-center justify-between">
              <span className="flex items-center gap-2 !w-fit">
                <img src={FCMB} alt="" />
                12345678 - FCMB
              </span>
              <span
                onClick={() => navigate('/add-account')}
                className="flex items-center gap-2 font-medium text-blue cursor-pointer !w-fit"
              >
                <img src={Pen} alt="" /> Edit
              </span>
            </div>
          }
        /> */}

					<h4 className="text-[12px] w-fit py-[.2rem] px-[.5rem] bg-grey100 rounded-lg text-grey400">
						Account created at: {getDateString(profile?.created_date)}
					</h4>

					<Button
						isValid={true}
						type={"button"}
						icon={EditIcon}
						onClick={() =>
							navigate("/error", {
								state: {
									title: "Edit Business Details",
									redirectTo: "mailto:support@payhaq.com",
									btnTitle: "Contact Support",
									message:
										"At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.",
								},
							})
						}
						title={"Edit Business Details"}
						className="!border border-blue !bg-transparent !text-blue"
					/>
				</div>
			</>
		</Layout>
	);
}
