import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _PROFILE } from "../../redux/customer/customerActions";
import { account_type } from "../../utils";
import { _GET_BANK } from "../../redux/dashboard/dashboardAction";
import { IndividualProfile } from "../../components";
import { MerchantProfile } from "../../components/merchant";

const BusinessProfile = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	useEffect(() => {
		dispatch(_PROFILE(user?.userType));
		dispatch(_GET_BANK());
	}, [user]);

	return <>{isMerchant ? <MerchantProfile /> : <IndividualProfile />}</>;
};

export default BusinessProfile;
