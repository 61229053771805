/* eslint-disable @typescript-eslint/no-explicit-any */
import { AccountType } from "../../types";
import api from "../../utils/api";

class EcardService {
	CREATE_ECARD({
		...data
	}: {
		first_name: string;
		last_name: string;
		phone_number: string;
		gender: string;
		residential_address: string;
		product_type: string;
		amount: string;
		duration?: string;
		frequency: string;
		ecard_name: string;
	}) {
		return api.post("/card/create", {
			...data,
		});
	}
	CREATE_INDIVIDUAL_E_CARD({
		...data
	}: {
		product_type: string;
		amount: string;
		duration?: string;
		frequency: string;
		ecard_name: string;
	}) {
		return api.post("/individual/ecard/create", {
			...data,
		});
	}

	UPDATE_ECARD({
		ecard_id,
		contributor_id,
		profile_pic,
		email,
		phone_number,
		gender,
		bvn,
		nin,
		product_type,
		minimum_amount,
		minimum_frequency,
		minimum_duration,
		residential_address,
		nok_first_name,
		nok_phone_number,
		nok_email_address,
	}: any) {
		return api.patch(`/card/update-contributor/${ecard_id}/${contributor_id}`, {
			profile_pic,
			email,
			phone_number,
			gender,
			bvn,
			nin,
			product_type,
			amount: String(minimum_amount),
			frequency: minimum_frequency,
			duration: minimum_duration,
			residential_address,
			nok_first_name,
			nok_phone_number,
			nok_email_address,
		});
	}
	UPDATE_INDIVIDUAL_E_CARD({ ecard_id, product_type, amount, frequency, duration }: any) {
		return api.patch(`/individual/ecard/update/${ecard_id}`, {
			product_type,
			amount: String(amount),
			frequency,
			duration,
		});
	}

	GET_E_CARDS(accountType: AccountType) {
		const url =
			accountType?.toLocaleLowerCase() === "individual"
				? "/individual/ecard/get"
				: "/card/get-ecards";
		return api.get(url);
	}

	GET_CONTRIBUTOR_E_CARDS(contributor_id: string) {
		return api.get(`/card/get-contributor-ecard/${contributor_id}`);
	}

	GET_CONTRIBUTORS() {
		return api.get("/contributor/get-contributors");
	}
}

export default new EcardService();
