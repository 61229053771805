/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

function Providers({ children }: { children: React.ReactNode }) {
	const persistor = persistStore(store);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{children}
			</PersistGate>
		</Provider>
	);
}

export default Providers;
