import React, { useRef, useState } from "react";
import {
	AccountIcon,
	Avatar,
	BlackChevron,
	FemaleAvatar,
	LogoutIcon,
	MaleAvatar,
} from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "../../hooks/use-outside-click/use-outside-click";
import { _SIGN_OUT } from "../../redux/auth/authActions";
import { useNavigate } from "react-router-dom";

export function UserProfile() {
	const ref = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { profile } = useSelector((state: RootState) => state.customer);
	const displayName = `${profile?.firstname || ""} ${profile?.lastname || ""}` || "";

	const handleClose = () => setIsOpen(false);
	const toggle = () => setIsOpen(!isOpen);
	useOutsideClick(handleClose, ref);

	return (
		<div className="relative" ref={ref}>
			<div className="w-fit h-8 flex items-center gap-3 cursor-pointer" onClick={toggle}>
				<img
					src={
						profile.geder === 1
							? MaleAvatar
							: profile.gender === 2
								? FemaleAvatar
								: Avatar
					}
					alt="avatar"
					className="w-8 h-8"
				/>
				<h4 className="text-black font-semibold text-xs">{displayName}</h4>
				<img src={BlackChevron} alt="avatar" className="" />
			</div>

			<AnimatePresence mode="wait">
				{isOpen && (
					<motion.div
						initial={{ height: 0 }}
						animate={{
							height: "auto",
							transition: {
								duration: 0.2,
							},
						}}
						exit={{
							height: 0,
							transition: {
								duration: 0.2,
							},
						}}
						className="flex flex-col gap-4 md:gap-6 absolute 
						right-[-14px] md:right-0 top-[40px] md:top-[50px] min-w-[172px] 
						rounded bg-white drop-shadow z-50 overflow-hidden"
					>
						<div
							className="flex items-center gap-4 pt-3 px-5 cursor-pointer"
							onClick={() => {
								navigate("/profile");
								handleClose();
							}}
						>
							<img src={AccountIcon} alt="" />
							<p className="text-grey400 text-sm md:text-base font-medium">Profile</p>
						</div>

						<div
							className="flex items-center gap-4 pb-3 px-5 cursor-pointer"
							onClick={() => {
								dispatch(_SIGN_OUT());
							}}
						>
							<img src={LogoutIcon} alt="" />
							<p className="text-grey400 text-sm md:text-base font-medium">Logout</p>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
