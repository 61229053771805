/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./dashboardService";
import { ISettlement } from "../../types";
import { PendingIcon } from "../../assets/icons";
import { _UPLOAD_FILE } from "../customer/customerActions";

export const _CREATE_SETTLEMENT = createAsyncThunk(
	"CREATE_SETTLEMENT",
	async (
		{
			navigate,
			settlement_amount,
			transaction_pin,
			mode_of_settlement,
			contributor_name,
			ecard_id,
			accountType,
		}: ISettlement,
		{ rejectWithValue },
	) => {
		try {
			const res = await dashboardService.CREATE_SETTLEMENT({
				settlement_amount,
				transaction_pin,
				mode_of_settlement,
				contributor_name,
				ecard_id,
				accountType,
			});

			if (accountType?.toLocaleLowerCase() === "individual") {
				navigate("/success", {
					state: {
						icon: PendingIcon,
						title: "Transaction pending",
						// message:
						//   'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
						redirectTo: "/dashboard",
					},
				});
			} else {
				navigate("/success", {
					state: {
						title: "Settlement made successfully",
						// message:
						//   'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
						redirectTo: "/dashboard",
					},
				});
			}

			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _MARK_SAVINGS = createAsyncThunk(
	"MARK_SAVINGS",
	async (
		{
			selected_dates,
			transaction_pin,
			contributor_name,
			contributor_id,
			ecard_id,
			navigate,
			proof_of_payment,
			accountType,
		}: any,
		{ rejectWithValue, dispatch },
	) => {
		const pop: any = await dispatch(_UPLOAD_FILE(proof_of_payment)).unwrap();

		try {
			const res = await dashboardService.MARK_SAVINGS({
				selected_dates,
				transaction_pin,
				contributor_name,
				contributor_id,
				ecard_id,
				accountType,
				proof_of_payment: pop,
			});

			if (accountType?.toLocaleLowerCase() === "individual") {
				navigate("/success", {
					state: {
						icon: PendingIcon,
						title: "Pending confirmation",
						// message:
						//   'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
						redirectTo: "/dashboard",
					},
				});
			} else {
				navigate("/success", {
					state: {
						title: "Savings have been marked successfully",
						// message:
						//   'At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.',
						redirectTo: "/dashboard",
					},
				});
			}

			return res.data;
		} catch (err: any) {
			const error = err.response.data;

			return rejectWithValue(error);
		}
	},
);

export const _ADD_ACCOUNT = createAsyncThunk(
	"ADD_BANK",
	async ({ bvn, bankName, accountNumber, customer_id, navigate }: any, { rejectWithValue }) => {
		try {
			const res = await dashboardService.ADD_BANK({
				bvn,
				bankName,
				accountNumber,
				customer_id,
				navigate,
			});

			navigate("/success", {
				state: {
					title: "Savings successful",
					message:
						"At just 30 years old, he is widely considered one of Europe’s most talented and accomplished young entrepreneurs and philosophical thinkers.",
					redirectTo: "/dashboard",
				},
			});

			return res.data;
		} catch (err: any) {
			const error = err.response.data;
			return rejectWithValue(error);
		}
	},
);

export const _GET_BANK = createAsyncThunk("GET_BANK", async () => {
	try {
		const res = await dashboardService.GET_BANK();
		return res.data;
	} catch (err: any) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const error = err.response.data;
	}
});
