import React, { useEffect } from "react";
import Layout from "../../../components/layout/layout";
import { _GET_CONTRIBUTORS } from "../../../redux/e-card/e_cardAction";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { CreateEcardForm, TopNavigation } from "../../../components";
import { account_type } from "../../../utils";
import CustomModal from "../../../components/custom-modal/custom-modal";

const ContributorsInformation = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user.userType);

	useEffect(() => {
		if (isMerchant) dispatch(_GET_CONTRIBUTORS());
	}, [dispatch]);

	return (
		<>
			{isMerchant ? (
				<Layout>
					<TopNavigation rightTitle="CREATE e-CARD" />
					<CreateEcardForm />
				</Layout>
			) : (
				<CustomModal>
					<CreateEcardForm />
				</CustomModal>
			)}
		</>
	);
};

export default ContributorsInformation;
