import React, { FC, ReactNode } from "react";

interface Props {
	title: string;
	value: string | ReactNode;
}

const ProfileItem: FC<Props> = ({ title, value }) => {
	return (
		<div className="flex flex-col gap-2">
			<p className="uppercase text-grey400 font-normal text-xs">{title}</p>
			<h5 className="text-sm font-semibold">{value}</h5>
		</div>
	);
};

export default ProfileItem;
