import { createSlice } from "@reduxjs/toolkit";
import { IEcardState } from "../../types";
import {
	_CREATE_E_CARD,
	_GET_CONTRIBUTOR_E_CARDS,
	_GET_E_CARDS,
	_GET_CONTRIBUTORS,
	_UPDATE_E_CARD,
	_CREATE_INDIVIDUAL_E_CARD,
	_UPDATE_INDIVIDUAL_E_CARD,
} from "./e_cardAction";

const initialState: IEcardState = {
	loading: false,
	error: null,
	eCard: {},
	eCards: [],
	contributors: [],
	contributor: {},
};

const e_cardSlice = createSlice({
	name: "e_card",
	initialState,
	reducers: {
		clearECardState: () => {
			return {
				...initialState,
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(_CREATE_E_CARD.fulfilled, (state, { payload }) => {
			state.eCard = payload;
			state.loading = false;
		});
		builder.addCase(_CREATE_E_CARD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_CREATE_E_CARD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});
		builder.addCase(_CREATE_INDIVIDUAL_E_CARD.fulfilled, (state, { payload }) => {
			state.eCard = payload;
			state.loading = false;
		});
		builder.addCase(_CREATE_INDIVIDUAL_E_CARD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_CREATE_INDIVIDUAL_E_CARD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_UPDATE_E_CARD.fulfilled, (state, { payload }) => {
			state.eCard = payload;
			state.loading = false;
		});
		builder.addCase(_UPDATE_E_CARD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_UPDATE_E_CARD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_UPDATE_INDIVIDUAL_E_CARD.fulfilled, (state, { payload }) => {
			state.eCard = payload;
			state.loading = false;
		});
		builder.addCase(_UPDATE_INDIVIDUAL_E_CARD.pending, state => {
			state.loading = true;
		});
		builder.addCase(_UPDATE_INDIVIDUAL_E_CARD.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_GET_CONTRIBUTORS.fulfilled, (state, { payload }) => {
			state.contributors = payload;
			state.loading = false;
		});
		builder.addCase(_GET_CONTRIBUTORS.pending, state => {
			state.loading = true;
		});
		builder.addCase(_GET_CONTRIBUTORS.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_GET_CONTRIBUTOR_E_CARDS.fulfilled, (state, { payload }) => {
			state.contributor = payload;
			state.loading = false;
		});
		builder.addCase(_GET_CONTRIBUTOR_E_CARDS.pending, state => {
			state.loading = true;
		});
		builder.addCase(_GET_CONTRIBUTOR_E_CARDS.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_GET_E_CARDS.fulfilled, (state, { payload }) => {
			state.eCards = payload;
			state.loading = false;
		});
		builder.addCase(_GET_E_CARDS.pending, state => {
			state.loading = true;
		});
		builder.addCase(_GET_E_CARDS.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});
	},
});

// eslint-disable-next-line no-empty-pattern
export const { clearECardState } = e_cardSlice.actions;
export default e_cardSlice.reducer;
