import React from "react";
import Layout from "../../components/layout/layout";
import { TopNavigation } from "../../components";

const Help = () => {
	return (
		<Layout>
			{" "}
			<TopNavigation middleTitle="Help & Earn" />
		</Layout>
	);
};

export default Help;
