import React, { FC, ReactNode } from "react";
import ScrollToTop from "../scroll-to-top/scroll-to-top";
import { useSelector } from "react-redux";
import { account_type, SidebarArray } from "../../utils";
import { ArrowBack, LogoWithText } from "../../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { ISidebarItem } from "../../types";
import { UserProfile } from "../individual";

interface Props {
	children: ReactNode;
	width?: number;
}

const Layout: FC<Props> = ({ children, width }) => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	const authUrls = [
		"/",
		"/account",
		"/sign-up",
		"/email-confirmation",
		"/error",
		"/success",
		"/forgot-password",
		"/create-password",
		"/create-pin",
	];

	const profileUrls = ["/profile", "/add-account"];

	return (
		<ScrollToTop>
			<div
				className={`bg-grey100 min-h-screen w-full no-scrollbar ${isMerchant ? "md:py-10" : ""} ${authUrls.includes(pathname) && "md:py-10"}  `}
			>
				<>
					{isMerchant || authUrls.includes(pathname) ? (
						<div
							className="relative w-full md:max-w-[500px] min-h-screen md:min-h-[437px] no-scrollbar
          					px-5 py-6 pb-10 md:py-10 mx-auto md:p-10 bg-white rounded-lg !overflow-hidden"
							style={{ maxWidth: width }}
						>
							{children}
						</div>
					) : (
						<div className="">
							<div className="bg-white h-[56px] md:h-[90px] flex items-center px-4 md:px-0">
								<div className="w-full max-w-[600px] mx-auto flex items-center justify-between">
									<img
										src={LogoWithText}
										alt="logo"
										className="md:w-[86px] md:h-6"
									/>

									<UserProfile />
								</div>
							</div>

							<div
								className={`relative w-full md:max-w-[600px] no-scrollbar
          						px-4 md:px-0 py-6 md:pt-10 pb-6 mx-auto  rounded-lg !overflow-hidden no-scrollbar `}
								style={{ maxWidth: width }}
							>
								{profileUrls.includes(pathname) ? (
									<div className="flex w-[55%] justify-between items-center">
										<img
											src={ArrowBack}
											alt="arrow back"
											className="cursor-pointer"
											onClick={() => navigate("/dashboard")}
										/>
										<Link
											to={"/profile"}
											className={`w-fit text-xs md:text-sm font-bold !text-black `}
										>
											Profile
										</Link>
									</div>
								) : (
									<div className="flex items-center gap-5 md:gap-8 w-full overflow-scroll">
										{SidebarArray(user?.userType).map(
											(item: ISidebarItem, index: number) => (
												<Link
													to={item.url?.[0] || "/dashboard"}
													key={index}
													className={`w-fit text-grey400 text-xs md:text-sm font-medium
												${item.url?.includes(pathname) ? "!font-bold !text-black" : ""}
												`}
												>
													{item.title}
												</Link>
											),
										)}
									</div>
								)}
								{children}
							</div>
						</div>
					)}
				</>
			</div>
		</ScrollToTop>
	);
};

export default Layout;
