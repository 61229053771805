import React from "react";
import Layout from "../../components/layout/layout";
import { ListCardWrapper } from "../../components";
import { Vault, VaultIcon } from "../../assets/icons";

const Vaults = () => {
	return (
		<Layout>
			<ListCardWrapper titleArray={[{ title: "Vaults", icon: VaultIcon }]}>
				<div className="flex flex-col gap-8 items-center">
					<div className="mt-10 text-center">
						<h2 className="text-lg md:text-2xl font-bold text-center">
							Grow your wealth, Grow your <br /> Dreams with Dashi Vault
						</h2>
						<p className="text-grey400 text-xs font-medium md:text-base mt-2">
							Earn up to 13.5% per annum on your savings
						</p>
					</div>
					<img src={Vault} alt="icon" className="w-[157px] h-[157px]" />

					<div className="text-sm md:text-base text-grey400 text-center pb-4">
						We&rsquo;ll send you a notification <br />{" "}
						<span className="text-black">
							as soon as this feature becomes available
						</span>
					</div>
				</div>
			</ListCardWrapper>
		</Layout>
	);
};

export default Vaults;
