import React, { FC, ReactNode, useRef } from "react";
import ScrollToTop from "../scroll-to-top/scroll-to-top";
import useOutsideClick from "../../hooks/use-outside-click/use-outside-click";
import { useNavigate } from "react-router-dom";

interface Props {
	children: ReactNode;
	className?: string;
	containerClassName?: string;
}

const CustomModal: FC<Props> = ({ children, className, containerClassName }) => {
	const ref = useRef(null);
	const navigate = useNavigate();

	useOutsideClick(() => navigate(-1), ref);

	return (
		<ScrollToTop>
			<div
				className={`min-h-screen pt-10 pb-[120px]  w-full modal-bg flex md:pt-[120px] lg:items-center justify-center ${containerClassName}`}
			>
				<div
					ref={ref}
					className={`p-5 md:p-[30px] w-[90%] max-w-[480px] h-fit bg-white 
          			!relative rounded-lg overflow- ${className} `}
				>
					{children}
				</div>
			</div>
		</ScrollToTop>
	);
};

export default CustomModal;
