import { AccountType } from "../../types";
import api from "../../utils/api";

class CustomerService {
	SIGN_IN(password: string, email_address: string) {
		return api.post("auth/login", { password, email_address });
	}
	CREATE_CUSTOMER(
		firstname: string,
		lastname: string,
		email_address: string,
		password: string,
		confirm_password: string,
		customer_type: string,
		phone_number: string,
	) {
		return api.post("/customer/create", {
			firstname: firstname,
			lastname: lastname,
			email_address,
			password,
			confirm_password,
			customer_type,
			phone_number,
		});
	}
	FORGOT_PASSWORD(email: string) {
		return api.post("/customer/forget-password", { email_address: email });
	}
	RESEND_OTP(email: string) {
		return api.post("/customer/otp/resend", { email_address: email });
	}
	CREATE_PIN(pin: string) {
		return api.post("/pin/create", { pin });
	}
	RESET_PIN({
		current_pin,
		new_pin,
		confirm_new_pin,
	}: {
		current_pin: string;
		new_pin: string;
		confirm_new_pin: string;
	}) {
		return api.put("/pin/reset", { current_pin, new_pin, confirm_new_pin });
	}
	OTP_VERIFICATION(otp: string, email_address: string | null) {
		return api.get(`/customer/otp/verify/${email_address}/${otp}`);
	}
	RESET_PASSWORD(
		email: string,
		token: string,
		new_password: string,
		confirm_new_password: string,
	) {
		const email_address = email;
		return api.patch(`/customer/reset-password/${email_address}?token=${token}`, {
			new_password,
			confirm_new_password,
		});
	}

	CHANGE_PASSWORD(current_password: string, new_password: string, confirm_new_password: string) {
		return api.patch(`/customer/change-password`, {
			current_password,
			new_password,
			confirm_new_password,
		});
	}
	GET_TRANSACTIONS() {
		return api.get("/customer/transaction_logs");
	}

	PROFILE(accountType: AccountType) {
		const url =
			accountType === "merchant" ? "/customer/merchant-profile" : "/individual/customer/get";
		return api.get(url);
	}

	COMPLETE_MERCHANT_PROFILE({
		business_name,
		business_address,
		CAC_number,
		means_of_identification,
		means_of_identification_upload_url,
		next_of_kin_name,
		next_of_kin_address,
		next_of_kin_relationship,
		next_of_kin_phone_number,
		next_of_kin_email_address,
	}: {
		business_name: string;
		business_address: string;
		CAC_number?: string;
		means_of_identification: string;
		means_of_identification_upload_url: string;
		next_of_kin_name: string;
		next_of_kin_address: string;
		next_of_kin_relationship: string;
		next_of_kin_phone_number: string;
		next_of_kin_email_address: string;
	}) {
		return api.patch("/customer/complete-merchant-profile", {
			business_name,
			business_address,
			CAC_number,
			means_of_identification,
			means_of_identification_upload_url,
			next_of_kin_name,
			next_of_kin_address,
			next_of_kin_relationship,
			next_of_kin_phone_number,
			next_of_kin_email_address,
		});
	}
	SIGN_OUT() {
		return api.post("/auth/invalidate-token");
	}
}

export default new CustomerService();
