import React, { FC } from "react";
import { generateColorFromName, getAbbr } from "../../utils";

interface Props {
	size?: "m" | "l";
	fullName: string;
}

export const Initials: FC<Props> = ({ size, fullName }) => {
	return (
		<div
			style={{
				backgroundColor: generateColorFromName(fullName),
			}}
			className={`inline-flex justify-center items-center text-white rounded-full overflow-hidden uppercase ${size === "m" ? "w-5 h-5 text-[8px]" : "w-7 h-7 md:w-10 md:h-10 text-xs md:text-sm"} `}
		>
			{getAbbr(fullName)}
		</div>
	);
};
