import React, { FC } from "react";

interface Props {
	title: string;
	isActive: boolean;
	onClick: () => void;
}

const TabItem: FC<Props> = ({ title, isActive, onClick }) => {
	return (
		<div
			onClick={onClick}
			className="w-fit h-[35px] px-5 py-2.5 bg-grey100 rounded-[32px] 
      justify-center items-center gap-2.5 inline-flex cursor-pointer"
		>
			<p
				className={`text-xs ${
					isActive ? "font-semibold text-black" : "text-grey400 font-medium"
				} `}
			>
				{title}
			</p>
		</div>
	);
};

export default TabItem;
