import React, { FC } from "react";
import SetupStep from "../setup-step/setup-step";

interface Props {
	title: string;
	subText: string;
	hasStep?: boolean;
	currentStep?: number;
	totalStep?: number;
}

const PageTitle: FC<Props> = ({ title, subText, hasStep, totalStep, currentStep }) => {
	return (
		<div className="flex items-center gap-2 justify-between">
			<div className="w-fit">
				<h1 className="text-base md:text-xl text-black font-bold md:leading-[24px]">
					{title}
				</h1>
				<h5 className="text-sm mt-1 md:mt-[10px] leading-6 text-grey400 !max-w-fit">
					{subText}
				</h5>
			</div>

			{hasStep && <SetupStep currentStep={currentStep} totalStep={totalStep} />}
		</div>
	);
};

export default PageTitle;
