/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { account_type, formatAmount, getDateString, getTimeString } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Initials } from "../shared";

interface Props {
	item: any;
}

const Transaction: FC<Props> = ({ item }) => {
	const { pathname } = useLocation();
	const { user } = useSelector((state: RootState) => state.auth);
	const [details, setDetails] = useState<any>({});
	const { profile } = useSelector((state: RootState) => state.customer);
	const { contributors } = useSelector((state: RootState) => state.e_card);

	const isMerchant = account_type(user?.userType);

	useEffect(() => {
		const contributor = contributors?.find(
			(contributor: any) =>
				item?.contributor_name?.includes(contributor?.first_name) &&
				item?.contributor_name?.includes(contributor?.last_name),
		);
		setDetails(isMerchant ? contributor : profile);
	}, [contributors, item, isMerchant, profile]);

	return (
		<Link
			state={{ transaction: item }}
			to="/transaction-details"
			className="flex items-center justify-between w-full"
		>
			<div className="flex items-center gap-5">
				<Initials
					fullName={
						isMerchant
							? `${details?.first_name || details?.firstname} ${details?.last_name || details?.lastname}`
							: `${details?.ecard_name}`
					}
				/>
				<div>
					<h6 className="text-xs font-semibold h-[15px]">
						{isMerchant
							? item?.contributor_name ||
								`${details?.first_name || details?.firstname} ${details?.last_name || details?.lastname}`
							: `${details?.ecard_name}`}
					</h6>
					<p className="mt-[6px] text-grey400 text-xs font-normal h-[15px]">
						{isMerchant ? (
							<>
								{pathname !== "/transactions" &&
									`${getDateString(item?.created_date)} @ `}{" "}
								{getTimeString(item?.created_date)}
							</>
						) : (
							<>{getTimeString(item?.created_date)}</>
						)}
					</p>
				</div>
			</div>

			<p
				className={`text-xs font-semibold tracking-wide text-end ${item?.transaction_type === "savings" ? "text-green" : "text-red"}`}
			>
				₦
				{item?.amount_collected
					? formatAmount(item?.amount_collected)
					: item?.amount_settled
						? formatAmount(item?.amount_settled)
						: formatAmount(item?.amount)}
			</p>
		</Link>
	);
};

export default Transaction;
