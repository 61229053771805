import React, { useState } from "react";
import CustomModal from "../custom-modal/custom-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { account_type, formatAmount } from "../../utils";
import { Button } from "../shared";
import { SuccessCheck } from "../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const IndividualEcardItem = ({ title, value }: { title: string; value: string | number }) => {
	return (
		<div className="flex items-center justify-between py-6 border-b border-grey100">
			<p className="text-sm text-grey400">{title}</p>
			<h6 className="font-medium text-black text-sm capitalize">{value}</h6>
		</div>
	);
};

export function IndividualEcardDetails() {
	const { state } = useLocation();
	const data = state?.data || "";
	const navigate = useNavigate();
	const [isCloseEcard, setIsCloseEcard] = useState<boolean>(false);
	const toggleCloseEcard = () => setIsCloseEcard(!isCloseEcard);
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	return (
		<>
			{!isCloseEcard ? (
				<CustomModal>
					<div>
						<p className="text-grey400 text-xs">eCard Name:</p>
						<h5 className="font-semibold text-black">{data?.ecard_name}</h5>
					</div>

					<IndividualEcardItem
						title={"Product Type:"}
						value={data?.product?.product_type}
					/>
					<IndividualEcardItem
						title={"Amount:"}
						value={`₦${formatAmount(data?.product?.minimum_amount)}`}
					/>
					<IndividualEcardItem
						title={"Frequency:"}
						value={data?.product?.minimum_frequency}
					/>
					{data?.product?.minimum_duration && (
						<IndividualEcardItem
							title={"Duration:"}
							value={data?.product?.minimum_duration}
						/>
					)}

					<div className="flex items-center gap-3 justify-end mt-8">
						<Button
							isValid={true}
							type={"button"}
							title={"Edit Details"}
							className="!w-[131px] !h-10 !text-xs"
							onClick={() => {
								isMerchant
									? navigate("/edit-e-card", { state: { data } })
									: navigate("/create-e-card/contributor-information", {
											state: { data },
										});
							}}
						/>
						<Button
							isValid={true}
							type={"button"}
							title={"Close eCard"}
							onClick={toggleCloseEcard}
							className="!w-[131px] !h-10 !bg-transparent border border-red !text-red !text-xs"
						/>
					</div>
				</CustomModal>
			) : (
				<CustomModal className="!max-w-[410px]">
					<div className="flex flex-col items-center justify-center gap-4">
						<img src={SuccessCheck} alt="icon" className="w-[60px]" />
						<h5 className="text-black font-semibold text-[18px]">Close eCard</h5>
						<p className="text-xs text-grey400 text-center max-w-[310px]">
							Are you sure you want to delete this your eCard “Hajj & Ummrah”. If you
							choose to proceed, your balance will be sent to your primary account
							details minus any charges incured.
						</p>
						<div className="flex items-center gap-3 justify-end">
							<Button
								isValid={true}
								type={"button"}
								title={"Cancel"}
								className="!w-[131px] !h-10 !text-xs"
								onClick={toggleCloseEcard}
							/>
							<Button
								isValid={true}
								type={"button"}
								title={"Yes Continue"}
								// onClick={toggleCloseEcard}
								className="!w-[131px] !h-10 !bg-transparent border border-red !text-red !text-xs"
							/>
						</div>
					</div>
				</CustomModal>
			)}
		</>
	);
}
