import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import { PendingIcon } from "../../assets/icons";
import SectionTitle from "../create-ecard/section-title/section-title";
import FileUpload from "../create-ecard/contributors-information/file-upload/file-upload";
import { Button } from "../../components";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { _MARK_SAVINGS } from "../../redux/dashboard/dashboardAction";
import { toastify } from "../../components/toastify/toastify";

const FundingTransactionStatus = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { state: urlData } = useLocation();
	const { loading } = useSelector((state: RootState) => state.dashboard);

	return (
		<CustomModal>
			<Formik
				validateOnMount
				initialValues={{
					proof_of_payment: "",
				}}
				onSubmit={async (values, { setErrors }) => {
					const errors = {};
					const payloadData = { ...values, ...urlData };
					if (Object.entries(errors).length === 0 && errors.constructor === Object) {
						try {
							await dispatch(_MARK_SAVINGS({ ...payloadData, navigate })).unwrap();
						} catch (error: any) {
							return toastify("error", error.message);
						}
					} else {
						setErrors(errors);
					}
				}}
			>
				{({ isValid, setFieldValue, handleSubmit }) => (
					<Form onSubmit={handleSubmit}>
						{" "}
						<div className="flex flex-col gap-[15px] items-center">
							<img src={PendingIcon} alt="icon" />
							<SectionTitle title="Transaction pending" className="!text-[18px]" />
							<p className="text-xs text-grey400 leading-5 text-center max-w-[310px]">
								At just 30 years old, he is widely considered one of Europe’s most
								talented and accomplished young entrepreneurs and philosophical
								thinkers.
							</p>
						</div>
						<div className="w-full flex flex-col gap-5 mt-8">
							{/* <InputField
								name="referrence"
								label={"Referrence"}
								placeholder={"Referrence"}
							/> */}
							<FileUpload
								type="id"
								name={"proof_of_payment"}
								label="Upload Receipt"
								placeholder="Choose file to upload"
								setFieldValue={value => setFieldValue("proof_of_payment", value)}
							/>
							<Button
								isValid={isValid}
								type={"submit"}
								title="Continue"
								className="!text-sm"
								isLoading={loading}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</CustomModal>
	);
};

export default FundingTransactionStatus;
