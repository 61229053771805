import React from "react";
import Layout from "../../../components/layout/layout";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import SectionTitle from "../section-title/section-title";
import { useLocation, useNavigate } from "react-router-dom";
import { VerificationType } from "../../../utils";
import FileUpload from "../contributors-information/file-upload/file-upload";
import { Button, InputField, SelectField, TopNavigation } from "../../../components";

const ECardVerification = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	return (
		<Layout>
			<TopNavigation rightTitle="CREATE E-CARD" />
			<div className="pt-[70px] md:pt-10 flex flex-col gap-10">
				<SectionTitle title={"Verification"} />

				<Formik
					validateOnMount
					initialValues={{
						nok_first_name: "",
						nok_last_name: "",
						nok_email_address: "",
						nok_phone_number: "",
						identification_type: "",
						identification_number: "",
						address: "",
						id_upload_url: "",
					}}
					validationSchema={Yup.object({
						nok_first_name: Yup.string().required("This is a required field"),
						nok_last_name: Yup.string().required("This is a required field"),
						nok_phone_number: Yup.string().required("This is a required field"),
						nok_email_address: Yup.string()
							.email("Invalid email address")
							.required("This is a required field"),
						identification_type: Yup.string().required("This is a required field"),
						identification_number: Yup.string().required("This is a required field"),
					})}
					onSubmit={(values, { setErrors }) => {
						const errors = {};
						if (Object.entries(errors).length === 0 && errors.constructor === Object) {
							navigate("/create-e-card/details", {
								state: { ...values, ...state },
							});
						} else {
							setErrors(errors);
						}
					}}
				>
					{({ isValid, handleSubmit, setFieldValue }) => (
						<Form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<SelectField
									data={VerificationType}
									label={"Identification Type"}
									placeholder={"Select type"}
									setFieldValue={(value: string) =>
										setFieldValue("identification_type", value)
									}
									className="md:w-[50%]"
								/>
								<InputField
									name="identification_number"
									label="Identification Number"
									placeholder="1234567890"
									className="md:w-[50%]"
								/>
							</div>

							<FileUpload
								type="id"
								label="Upload ID"
								name="id_upload_url"
								placeholder="Take picture or upload image..."
								setFieldValue={value => setFieldValue("id_upload_url", value)}
							/>

							<SectionTitle title="Next of Kin" className="my-2.5" />

							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<InputField
									name="nok_first_name"
									label="First name"
									placeholder="eg John"
									className="md:w-[50%]"
								/>
								<InputField
									name="nok_last_name"
									label="Last name"
									placeholder="eg John"
									className="md:w-[50%]"
								/>
							</div>

							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<InputField
									type="email"
									name="nok_email_address"
									label="Email Address"
									placeholder="email@email.com"
									className="md:w-[50%]"
								/>
								<InputField
									type="tel"
									name="nok_phone_number"
									label="Phone number"
									placeholder="80 **** *****"
									className="md:w-[50%]"
									// setFieldValue={(value: string) =>
									//   setFieldValue('nok_phone_number', value)
									// }
								/>
							</div>

							<div className="flex flex-col gap-[25px]">
								<Button
									isValid={isValid}
									type={"submit"}
									title={"Continue - 2 / 3"}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Layout>
	);
};

export default ECardVerification;
