import React from "react";
import CustomModal from "../../components/custom-modal/custom-modal";
import { SuccessCheck } from "../../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components";

const Success = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	return (
		<CustomModal className="md:!w-[410px] md:px-[53px] py-10">
			<div className="flex flex-col items-center justify-center gap-[20px]">
				<img
					src={state.icon || SuccessCheck}
					alt="check"
					className="w-[70px] md:w-[96px]"
				/>
				<div className="text-center">
					<h2 className="text-center text-slate-900 text-lg font-semibold">
						{state.title}
					</h2>
					<p className="mt-2.5 text-center text-neutral-400 text-xs font-normal leading-5 h-fit">
						{state.message}
					</p>
				</div>
				<Button
					isValid={true}
					type={"button"}
					title={state?.btnTitle ? state?.btnTitle : "Continue"}
					onClick={() => navigate(state?.redirectTo)}
				/>

				{state?.lowerText && (
					<Link to={state?.lowerTextUrl} className="text-sm text-blue font-semibold">
						Later
					</Link>
				)}
			</div>
		</CustomModal>
	);
};

export default Success;
