import React from "react";
import { ListCardWrapper } from "./list-card-wrapper";
import EcardLogs from "../../pages/e-card/component/EcardLogs";
import { Button } from "../shared";
import { useNavigate } from "react-router-dom";
import { ECard } from "../../assets/icons";

export function IndividualEcardPage() {
	const navigate = useNavigate();

	return (
		<ListCardWrapper
			titleArray={[{ title: "All eCards", icon: ECard }]}
			actionComponent={
				<Button
					isValid={true}
					type={"button"}
					title={"Create New eCard"}
					className="!w-fit !bg-lightBlue !text-blue !h-[35px] !text-xs"
					onClick={() => navigate("/create-e-card/contributor-information")}
				/>
			}
		>
			<div className="pt-0">
				<EcardLogs query={""} />
			</div>
		</ListCardWrapper>
	);
}
