import React, { useState } from "react";
import LatestTransactions from "../latest-transactions/latest-transactions";
import { Link } from "react-router-dom";
import { Calendar, Menu, UserAvatar } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { account_type, thisDay } from "../../../../utils";
import Balance from "./components/balance/balance";
import DashboardActions from "./components/dashboard-actions/dashboard-actions";
import Sidebar from "./components/sidebar/sidebar";
import { RootState } from "../../../../redux/store";

const MerchantDashoard = () => {
	const { profile } = useSelector((state: RootState) => state.customer);

	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

	const displayName = profile?.customer_name;

	return (
		<div className="!relative !overflow-hidden min-h-screen md:min-h-[700px]">
			<div className="flex items-center justify-between">
				<div className="flex items-center gap-4">
					<Link to={"/profile"}>
						<img src={UserAvatar} alt="avatar" className="w-10 h-10" />
					</Link>
					<h4 className="text-sm font-semibold">{displayName}</h4>
				</div>
				<div
					onClick={toggleSidebar}
					className="flex items-center gap-[15px] cursor-pointer"
				>
					<img src={Menu} alt="menu icon" className="w-6 h-6" />
				</div>
			</div>

			<div>
				<div className="relative mt-10 w-full h-[140px] bg-black rounded-[10px] px-[25px] py-6">
					<span className="flex items-center gap-2.5">
						<img src={Calendar} alt="" className="white-icon" />
						<p className="text-grey200 text-xs font-normal leading-[14px]">{thisDay}</p>
					</span>

					<Balance isMerchant={isMerchant} />
				</div>
				<div className="bg-lightBlue h-[140px] rounded-[10px] mt-[-130px] mx-[10px]" />
			</div>

			<DashboardActions isMerchant={isMerchant} />

			<LatestTransactions />
			<Sidebar isOpen={isSidebarOpen} onClick={toggleSidebar} />
		</div>
	);
};

export default MerchantDashoard;
