import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	DashboardTransactions,
	IndividualSectionTitle,
	ListCardWrapper,
} from "../../../../components";
import { ECard, VaultIcon } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { formatAmount } from "../../../../utils";

const IndividualDashboard = () => {
	const navigate = useNavigate();
	const { profile } = useSelector((state: RootState) => state.customer);

	return (
		<main>
			<div className="pt-8 flex flex-col gap-4 md:flex-row md:items-start">
				<div
					className="p-5 rounded-xl flex flex-col justify-between w-full h-[216px]"
					style={{ background: "#E1F3E0" }}
				>
					<IndividualSectionTitle title={"Total Savings"} icon={ECard} />
					<div className="flex flex-col gap-2">
						<h4 className="text-black text-2xl font-bold">
							{`₦${formatAmount(profile?.total_amount_collected || 0)}` || "₦0.00"}
						</h4>
						<Link
							to={"/create-e-card/contributor-information"}
							className="text-xs font-medium underline underline-offset-2 text-black"
						>
							Create New eCard
						</Link>
					</div>

					<div className="flex items-center gap-[7px]">
						<Button
							isValid={true}
							type={"button"}
							title={"Mark Savings"}
							className="!text-xs w-fit !px-4 !h-[40px]"
							onClick={() => navigate("/mark-savings")}
						/>
						<Button
							isValid={true}
							type={"button"}
							title={"Withdraw"}
							onClick={() => navigate("/settlement")}
							className="!text-xs bg-lightBlue !text-blue w-fit !h-[40px]"
						/>
					</div>
				</div>

				<div
					className="p-5 rounded-xl flex flex-col justify-between w-full h-[216px]"
					style={{ background: "#E1EEF4" }}
				>
					<IndividualSectionTitle title={"Vaults"} icon={VaultIcon} />
					<h4 className="font-semibold text-black text-base">
						Earn up to <span className="font-bold">13.5%</span> per <br /> annum on your
						savings
					</h4>
					<div className="flex items-center gap-[7px]">
						<Button
							isValid={true}
							type={"button"}
							title={"Coming Soon!!!"}
							onClick={() => navigate("/vaults")}
							className="!text-xs !h-[40px]"
						/>
					</div>
				</div>
			</div>

			<ListCardWrapper
				titleArray={[{ title: "Latest Transactions" }]}
				actionComponent={
					<Link to={"/transactions"} className="text-xs text-blue font-semibold">
						See all
					</Link>
				}
			>
				<DashboardTransactions />
			</ListCardWrapper>
		</main>
	);
};

export default IndividualDashboard;
