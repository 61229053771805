import React, { FC } from "react";
import ActionButton from "../action-button/action-button";
import { ECard, EarningsIcon, MarkSavings, Settlement } from "../../../../../../assets/icons";
import type { AccountTypeProps } from "../balance/balance";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";

const DashboardActions: FC<AccountTypeProps> = ({ isMerchant }) => {
	const { profile } = useSelector((state: RootState) => state.customer);

	const navigate = useNavigate();
	const hasBusinessProfile = profile.business_name !== null && profile.business_address !== null;

	return (
		<div className="mt-[30px] mb-10">
			{isMerchant ? (
				<div className="flex items-center justify-between mt-[30px] gap-3">
					<ActionButton
						title={"Create e-Card"}
						icon={ECard}
						isPurple
						onClick={
							hasBusinessProfile
								? () => navigate("/create-e-card/contributor-information")
								: () => navigate("/verification")
						}
					/>
					<ActionButton
						title={"Mark Savings"}
						icon={MarkSavings}
						onClick={
							hasBusinessProfile
								? () =>
										navigate("/mark-savings", {
											state: { isMerchant: isMerchant },
										})
								: () => navigate("/verification")
						}
					/>
					<ActionButton
						title={"Settlement"}
						icon={Settlement}
						onClick={
							hasBusinessProfile
								? () => navigate("/settlement")
								: () => navigate("/verification")
						}
					/>
					<ActionButton
						title={"Earnings"}
						icon={EarningsIcon}
						onClick={() => navigate("/dashboard")}
					/>
				</div>
			) : (
				<div className="flex items-center justify-center w-full gap-0">
					<ActionButton
						title={"Create e-Card"}
						icon={ECard}
						isPurple
						onClick={() => navigate("/create-e-card/contributor-information")}
					/>
					<ActionButton
						title={"Mark Savings"}
						icon={MarkSavings}
						onClick={() =>
							navigate("/mark-savings", {
								state: { isMerchant: isMerchant },
							})
						}
					/>
					<ActionButton
						title={"Withdraw Funds"}
						icon={Settlement}
						onClick={() => navigate("/settlement")}
					/>
				</div>
			)}
		</div>
	);
};

export default DashboardActions;
