/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Link } from "react-router-dom";
import { DashboardTransactions } from "../../../../components";

const LatestTransactions = () => {
	return (
		<div className="pt-10 border-t border-grey100 ">
			<div className="flex items-center justify-between">
				<p className="text-center text-grey400 text-xs font-medium">Latest Transactions</p>
				<Link to="/transactions" className="text-center text-blue text-xs font-semibold">
					See all
				</Link>
			</div>

			<DashboardTransactions />
		</div>
	);
};

export default LatestTransactions;
