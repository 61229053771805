import React, { FC } from "react";
import { Chevron } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

interface Props {
	onClick?: () => void;
}

const BackButton: FC<Props> = ({ onClick }) => {
	const navigate = useNavigate();

	return (
		<span
			onClick={
				onClick
					? onClick
					: () => {
							navigate(-1);
						}
			}
			className="flex items-center gap-[10px] w-fit cursor-pointer"
		>
			<span className="w-5 h-5 rounded-full border border-grey200">
				<img src={Chevron} alt="chevron" className="rotate-90 mt-[0.5px] ml-[0.5px]" />
			</span>
			<p className="text-grey400 text-xs font-normal leading-[14px]">Go back</p>
		</span>
	);
};

export default BackButton;
