import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
	title: string;
	value: string;
	hasBtn?: boolean;
}

const DashboardItem: FC<Props> = ({ title, value, hasBtn }) => {
	return (
		<div className="flex flex-col gap-2.5">
			<p className="text-grey400 text-xs font-normal leading-[14px]">{title}</p>
			<div className="flex items-center gap-2">
				<h5 className="text-white text-lg md:text-2xl font-bold leading-[30px]">{value}</h5>
				{hasBtn && (
					<Link
						to="/e-card/logs"
						className="h-6 py-[6px] 
						flex items-center justify-center px-2.5 rounded-[50px]
						text-white text-xs bg-[#ffffff1A] cursor-pointer"
					>
						View all
					</Link>
				)}
			</div>
		</div>
	);
};

export default DashboardItem;
