/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { account_type, formatAmount } from "../../utils";
import { _GET_CONTRIBUTORS, _GET_E_CARDS } from "../../redux/e-card/e_cardAction";
import { SelectField } from "./select-field";
import DateRangePicker from "../../components/date-range-picker/date-range-picker";
import { Button } from "./button";

export const MarkSavingsForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const [contributor, setContributor] = useState<any>();
	const [selectedEcard, setSelectedEcard] = useState<any>();
	const { contributors, eCards } = useSelector((state: RootState) => state.e_card);
	const { user } = useSelector((state: RootState) => state.auth);
	const isMerchant = account_type(user?.userType);

	const calculateAmount = (product: any, dateCount: number) => {
		const minAmount = product.minimum_amount;
		const frequency = product.minimum_frequency;

		if (frequency === "weekly") {
			return minAmount * Math.ceil(dateCount / 7);
		} else if (frequency === "bi-weekly") {
			return minAmount * Math.ceil(dateCount / 14);
		} else {
			return minAmount * dateCount;
		}
	};

	useEffect(() => {
		dispatch(_GET_E_CARDS(user?.userType));
		if (isMerchant) {
			dispatch(_GET_CONTRIBUTORS());
		}
	}, [user?.userType]);

	return (
		<Formik
			validateOnMount
			// enableReinitialize
			initialValues={{
				contributor_id: "",
				ecard_id: "",
				contributor_name: "",
				dateCount: "",
				selected_dates: "",
			}}
			validationSchema={
				isMerchant
					? Yup.object({
							contributor_id: Yup.string().required("This is a required field"),
							ecard_id: Yup.string().required("This is a required field"),
							contributor_name: Yup.string().required("This is a required field"),
							// selected_dates: Yup.array().required('This is a required field'),
						})
					: Yup.object({
							ecard_id: Yup.string().required("This is a required field"),
						})
			}
			onSubmit={(values, { setErrors }) => {
				const errors = {};
				if (Object.entries(errors).length === 0 && errors.constructor === Object) {
					navigate(isMerchant ? "/confirm-transaction " : "/payment-option", {
						state: {
							type: "savings",
							...values,
							amount:
								selectedEcard && selectedEcard?.product?.minimum_frequency
									? formatAmount(
											calculateAmount(
												selectedEcard.product,
												Number(values.dateCount),
											),
										)
									: "0",
						},
					});
				} else {
					setErrors(errors);
				}
			}}
		>
			{({ isValid, handleSubmit, setFieldValue, values }) => (
				<Form
					onSubmit={handleSubmit}
					className={`flex flex-col gap-[30px] ${isMerchant ? "mt-[60px] md:mt-10" : "mt-5"}`}
				>
					{isMerchant && (
						<SelectField
							isContributor
							data={contributors}
							label={"Select Contributor*"}
							placeholder={"eg Doe"}
							setValue={(item: any) => {
								setFieldValue("contributor_id", item.contributor_id);
								setContributor(item);
								if (item?.ecards?.length === 1) {
									const ecard = item?.ecards[0];
									setSelectedEcard(ecard);
									setFieldValue("ecard_id", ecard.ecard_id);
								}
							}}
							setFieldValue={(value: string) =>
								setFieldValue("contributor_name", value)
							}
						/>
					)}

					{((isMerchant && contributor?.ecards?.length > 1) || !isMerchant) && (
						<SelectField
							data={isMerchant ? contributor?.ecards : eCards}
							label={"Select eCard*"}
							placeholder={"eg Savings"}
							setValue={(item: any) => {
								setFieldValue("ecard_id", item.ecard_id);
								setSelectedEcard(item);
							}}
							setFieldValue={() => null}
						/>
					)}

					{selectedEcard && (
						<DateRangePicker
							contributor={contributor}
							selectedEcard={selectedEcard}
							setFieldValue={(value: string, count: number) => {
								setFieldValue("selected_dates", value);
								setFieldValue("dateCount", count);
							}}
						/>
					)}

					<Button
						isValid={isValid && Boolean(values.selected_dates)}
						type={"submit"}
						// title={`Pay N${selectedEcard && selectedEcard?.product?.minimum_frequency !== 'daily' ? formatAmount(selectedEcard?.product?.minimum_amount) : selectedEcard?.product?.minimum_frequency !== 'daily' ? formatAmount(selectedEcard?.product?.minimum_amount * Number(values.dateCount)) : '0'}`}
						title={`Pay N${selectedEcard && selectedEcard?.product?.minimum_frequency ? formatAmount(calculateAmount(selectedEcard.product, Number(values.dateCount))) : "0"}`}
					/>

					<div className={`${isMerchant ? "pb-10" : ""}`} />
				</Form>
			)}
		</Formik>
	);
};
