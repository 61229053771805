import { createSlice } from "@reduxjs/toolkit";
import { _SIGN_IN, _SIGN_OUT, _CURRENT_USER, _DECODED_USER } from "./authActions";
import { IAuthState } from "../../types";
import isEmpty from "is-empty";

const initialState: IAuthState = {
	loading: false,
	error: null,
	isAuthenticated: false,
	user: {},
	forgotPassword: {},
	otpVerification: {},
	createPassword: {},
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		clearAuthState: () => {
			return {
				...initialState,
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(_SIGN_IN.fulfilled, (state, { payload }) => {
			state.user = payload.decoded;
			state.loading = false;
		});
		builder.addCase(_SIGN_IN.pending, state => {
			state.loading = true;
		});
		builder.addCase(_SIGN_IN.rejected, (state, { payload }) => {
			state.error = payload;
			state.loading = false;
		});

		builder.addCase(_CURRENT_USER.fulfilled, (state, { payload }) => {
			state.isAuthenticated = !isEmpty(payload);
			state.loading = false;
		});

		builder.addCase(_DECODED_USER.fulfilled, (state, { payload }) => {
			state.user = payload;
			state.loading = false;
		});

		builder.addCase(_SIGN_OUT.fulfilled, state => {
			state.loading = false;
			state.error = null;
		});
	},
});

// eslint-disable-next-line no-empty-pattern
export const { clearAuthState } = authSlice.actions;
export default authSlice.reducer;
