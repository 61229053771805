/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Layout from "../../../components/layout/layout";
import SectionTitle from "../section-title/section-title";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Duration, Frequency, ProductType } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { _CREATE_E_CARD } from "../../../redux/e-card/e_cardAction";
import { toastify } from "../../../components/toastify/toastify";
import { Button, InputField, SelectField, TopNavigation } from "../../../components";

const ECardDetails = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const { loading } = useSelector((state: RootState) => state.e_card);
	const [selectedProduct, setSelectedProduct] = useState<"fixed" | "flexible" | null>(null);

	return (
		<Layout>
			<TopNavigation rightTitle="CREATE E-CARD" />
			<div className="pt-[70px] md:pt-10 flex flex-col gap-10">
				<SectionTitle title={"E-Card Details"} />

				<Formik
					validateOnMount
					initialValues={{
						product_type: "",
						amount: "",
						frequency: "",
						duration: "",
					}}
					validationSchema={Yup.object({
						product_type: Yup.string().required("This is a required field"),
						amount: Yup.string().required("This is a required field"),
						frequency: Yup.string().required("This is a required field"),
						duration: Yup.string().required("This is a required field"),
					})}
					onSubmit={async (values, { setErrors }) => {
						const errors = {};
						const payloadData = { ...state, ...values, navigate };

						if (Object.entries(errors).length === 0 && errors.constructor === Object) {
							try {
								const res: any = await dispatch(
									_CREATE_E_CARD(payloadData),
								).unwrap();
							} catch (err: any) {
								toastify("error", err.message);
							}
						} else {
							setErrors(errors);
						}
					}}
				>
					{({ isValid, handleSubmit, setFieldValue }) => (
						<Form onSubmit={handleSubmit} className="flex flex-col gap-[30px]">
							<SelectField
								data={ProductType}
								label={"Product Type*"}
								placeholder={"Select type"}
								setFieldValue={(value: string) =>
									setFieldValue("product_type", value)
								}
							/>
							<InputField
								type="string"
								name="amount"
								label="Amount*"
								placeholder="0.00"
							/>

							<div className="w-full flex flex-col  md:flex-row gap-[30px] md:gap-5">
								<SelectField
									data={Frequency}
									label={"Frequency*"}
									placeholder={"Select frequency"}
									setFieldValue={(value: string) =>
										setFieldValue("frequency", value)
									}
									className="md:w-[50%]"
								/>
								{/* {selectedProduct === 'fixed' && ( */}
								<SelectField
									data={Duration}
									label={"Duration*"}
									placeholder={"Select duration"}
									setFieldValue={(value: string) =>
										setFieldValue("duration", value)
									}
									className="md:w-[50%]"
								/>
								{/* )} */}
							</div>

							<div className="flex flex-col gap-[25px]">
								<Button
									isValid={isValid}
									isLoading={loading}
									type={"submit"}
									title={"Create E-Card - 3 / 3"}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Layout>
	);
};

export default ECardDetails;
